<template>
  <div class="yx-v2">
    <div class="yx-block bg1">
      <div class="yx-footer-page yx-header">
        <p>
          <img src="../../../static/imgs/idphoto-v2/yx/m_logo2.png" alt="" />
          <span
            ><a class="blue" @click="showAuthorityBox">应用权限</a
            >&nbsp;|&nbsp;<a
              class="blue"
              href="https://oss.puwu.info/idphoto/web/privacy.html"
              >隐私协议</a
            ></span
          >
        </p>
        <!-- <p>
              地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
            </p>
            <p>邮箱：feedback@techpuwu.com &nbsp; 电话：0512-87816685</p> -->
      </div>

      <div class="yx-bg1-title">简单易用的<br />证件照制作软件</div>
      <div class="yx-bg1-mintitle">一个好的印象，一个好的开始</div>
      <div class="yx-bg1-btn">
        <div class="btn" @click="download">免费下载使用</div>
        <div class="yx-quanxian">
          <p>【 V2.4.6 版本 · 软件大小 21.6MB 】</p>
          <p>开发者：苏州普物科技有限公司</p>
        </div>
      </div>

      <div>
        <lottie
          :options="defaultOptions"
          :height="245"
          :width="286"
          v-on:animCreated="handleAnimation"
        />
      </div>

      <div class="yx-bg1-list">
        <div class="list line"><div class="name bg1">高清证件照</div></div>
        <div class="list line"><div class="name bg2">自然美颜</div></div>
        <div class="list"><div class="name bg3">证件照换底</div></div>
        <div class="list line"><div class="name bg4">发丝级抠图</div></div>
        <div class="list line"><div class="name bg5">一照多用</div></div>
        <div class="list"><div class="name bg6">售后无忧</div></div>
      </div>

      <!-- <div class="yx-bg1-more" @click="slideTo(1)">
        <p>向上滑动查看更多</p>
      </div> -->
    </div>

    <div class="yx-block bg2">
      <div class="yx-bg2-title">
        <div class="yx-bg2-tip">为什么</div>
        <div class="yx-bg2-warn">选择 <span>印象证件照</span></div>
        <div class="yx-bg2-txt">
          先进的人脸识别算法，准确裁剪出标准证件照，专业
          美颜美型算法打造自然妆感，独家高清人像算法加持， 确保证件照更合格。
        </div>
      </div>
      <div class="yx-bg2-main">
        <div class="yx-bg2-left">
          <div class="bg2-left-title">印像证件照</div>
          <div class="bg2-left-list">
            <img src="../../../static/imgs/idphoto-v2/yx/pic_2_1.png" alt="" />
            <div class="intro"><b>人工智能算法美颜</b></div>
          </div>
          <div class="bg2-left-list">
            <img src="../../../static/imgs/idphoto-v2/yx/pic_2_3.png" alt="" />
            <div class="intro"><b>独家高清人像</b>技术</div>
          </div>
          <div class="bg2-left-list">
            <div class="intro f-left">
              <p><b>免费修改</b>文件大小/分辨率/<br />重命名/添加文字</p>
            </div>
          </div>
          <div class="bg2-left-list">
            <div class="intro f-left">
              在线客服/微信客服/电话客<br />服/全天候为您答疑
            </div>
          </div>
          <div class="bg2-left-list">
            <div class="intro f-left">全网最低价2元,<br />纸质版包邮</div>
          </div>
        </div>
        <div class="yx-bg2-right">
          <div class="bg2-right-title">普通证件照</div>
          <div class="bg2-right-list">
            <img src="../../../static/imgs/idphoto-v2/yx/pic_2_2.png" alt="" />
            <div class="intro">其他传统算法，<br />不真实</div>
          </div>
          <div class="bg2-right-list">
            <img src="../../../static/imgs/idphoto-v2/yx/pic_2_4.png" alt="" />
            <div class="intro">不支持此功能</div>
          </div>
          <div class="bg2-right-list f40">
            <div class="intro">不支持此功能</div>
          </div>
          <div class="bg2-right-list">
            <div class="intro f-left">无客服处理，<br />或处理不及时</div>
          </div>
          <div class="bg2-right-list">
            <div class="intro f-left">最低4元，<br />甚至高达30元</div>
          </div>
        </div>
      </div>
    </div>

    <div class="yx-block bg3">
      <div class="yx-bg3-title">
        <div class="bg3-title">10秒轻松获得<em></em></div>
        <div class="bg3-title">相馆级专业证件照</div>
        <div class="bg3-tip">
          页面简单流畅，只需3步，即可制作标准证件照， 第一次使用也可熟练制作。
        </div>
      </div>
      <div>
        <lottie
          :options="defaultOptions2"
          :height="300"
          :width="300"
          v-on:animCreated="handleAnimation2"
        />
      </div>
      <div class="yx-bg3-step">
        <div class="yx-bg3-list"><span>Step1</span>拍摄/上传照片，系统抠图</div>
        <div class="yx-bg3-list"><span>Step2</span>编辑底色、美颜清晰度</div>
        <div class="yx-bg3-list"><span>Step3</span>一键保存到相册</div>
      </div>
    </div>

    <div class="yx-block bg4">
      <div class="yx-bg-title">
        <div class="bg-title">
          独家<b>人脸高清</b>技术<br />让手机拍出相馆级级画质
        </div>
        <div class="bg-tip">
          独家图像生成技术，清晰放大人像，五官肤质优化， 打造专业相机拍摄效果。
        </div>
      </div>
      <div class="bg-pic">
        <img src="../../../static/imgs/idphoto-v2/yx/pic_4.png" alt="" />
      </div>
      <div class="bg4-tab">
        <span>普通证件照</span><b></b><span class="wran">印象证件照</span>
      </div>
    </div>

    <div class="yx-block bg5">
      <div class="yx-bg-title">
        <div class="bg-title">满足<b>500+</b>种<br />证件照尺寸要求</div>
        <div class="bg-tip">
          专业证件照制作软件，囊括500+证件照制作尺寸，满足你所有的证件照需求。
        </div>
        <div class="bg5-pic">
          <img src="../../../static/imgs/idphoto-v2/yx/pic_5_1.png" alt="" />
        </div>
      </div>
    </div>

    <div class="yx-block bg6">
      <div class="yx-bg-title">
        <div class="bg-title bg6-title"><b>免费修改</b><em></em></div>
        <div class="bg-title bg6-title">文件大小、dpi、重命名、添加文字</div>
        <div class="bg-tip">
          照片保存成功后，均可免费无限次修改，确保照片合格好用。
        </div>
      </div>
      <div class="bg4-tab bg6-tab">
        <span>修改前</span><b></b><span>修改后</span>
      </div>
      <div class="bg6-pic">
        <img src="../../../static/imgs/idphoto-v2/yx/pic_6.png" alt="" />
      </div>
      <div class="bg6-data"><span>20kb</span><span>200kb</span></div>
      <div class="bg6-list-wrap">
        <div class="bg6-list"><span>改文件大小</span></div>
        <div class="bg6-list"><span>修改分辨率</span></div>
        <div class="bg6-list"><span>照片重命名</span></div>
        <div class="bg6-list"><span>图片加文字</span></div>
      </div>
    </div>

    <div class="yx-block bg7">
      <div class="yx-bg-title yx-bg7-title">
        <div class="bg-title">7*24小时客服在线答疑</div>
        <div class="bg-tip">
          专业客服全天答疑，针对证件照的任何问题均可咨询客服，我们将竭诚为您服务。
        </div>
      </div>
      <div class="bg7-pic">
        <img src="../../../static/imgs/idphoto-v2/yx/pic_7_1.png" alt="" />
      </div>
      <!-- <div class="bg7-private">
            <div class="bg7-private-title">
              <em></em>照片隐私严格保护<b></b>
            </div>
            <div class="bg7-private-title font12">仅用作制作证件照</div>
            <p>
              您制作上传的照片，14天后自动销毁，绝不使用于其他途径，请放心使用。
            </p>
          </div> -->
      <div class="bg7-evaluate">
        <div class="bg7-evaluate-title">真实用户评价</div>
        <div class="bg7-evaluate-tip">
          已有<span>25341</span>个用户给出五星好评，好评率<span>98%</span>
        </div>
        <div class="bg7-score">
          <div class="bg7-details">
            <div class="bg7-details-name">
              <p>4.9分</p>
              <span>综合评分</span>
            </div>
          </div>
        </div>
        <div class="bg7-sliders">
          <swiper ref="evalSwiper" :options="evalSwiperOptions">
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img
                      src="https://oss.puwu.info/public/web/header/1.jpg"
                      alt=""
                    />
                  </p>
                  最美***
                </div>
                <div class="bg7-slider-txt">
                  作为多年证件照老用户，真心觉得证件照制作是用心做产品的app，精细打磨的效果，努力开辟新功能，都很惊喜。希望越来越好。点赞
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img
                      src="https://oss.puwu.info/public/web/header/2.jpg"
                      alt=""
                    />
                  </p>
                  可爱的***
                </div>
                <div class="bg7-slider-txt">
                  证件照软件很好用，一键换背景超级满意 
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img
                      src="https://oss.puwu.info/public/web/header/3.jpg"
                      alt=""
                    />
                  </p>
                  胖三***
                </div>
                <div class="bg7-slider-txt">
                  软件功能丰富，有不同的照片尺寸，还能换正装
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img
                      src="https://oss.puwu.info/public/web/header/4.jpg"
                      alt=""
                    />
                  </p>
                  你的***
                </div>
                <div class="bg7-slider-txt">
                  拍了一张100KB太大,然后可以免费改成50KB,赞赞赞
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img
                      src="https://oss.puwu.info/public/web/header/5.jpg"
                      alt=""
                    />
                  </p>
                  红衣少***
                </div>
                <div class="bg7-slider-txt">
                  这个比别的拍的清晰，还有美颜，喜欢！
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img
                      src="https://oss.puwu.info/public/web/header/6.jpg"
                      alt=""
                    />
                  </p>
                  帅萌的***
                </div>
                <div class="bg7-slider-txt">
                  疫情复工公司同事推荐用来拍通行证照片的，拍得证件照不错呐!
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img
                      src="https://oss.puwu.info/public/web/header/7.jpg"
                      alt=""
                    />
                  </p>
                  王七的***
                </div>
                <div class="bg7-slider-txt">证件照制作效果也很自然，好评！</div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="bg7-sliders-details">
                <div class="bg7-slider-name">
                  <p>
                    <img src="../../../static/imgs/vr/vr-header2.png" alt="" />
                  </p>
                  夏沫***
                </div>
                <div class="bg7-slider-txt">
                  儿子上小学了需要拍 直接用手机拍的 太方便了
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="yx-footer-page">
        <p>
          开发者：苏州普物科技有限公司
          <a href="http://beian.miit.gov.cn" target="_blank"
            >苏ICP备18052115号-6</a
          >
        </p>
        <p>
          印象证件照 V2.4.3
          <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
            class="blue"
            href="https://oss.puwu.info/idphoto/web/privacy.html"
            >隐私协议</a
          >
        </p>
        <!-- <p>
              地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
            </p>
            <p>邮箱：feedback@techpuwu.com &nbsp; 电话：0512-87816685</p> -->
      </div>
    </div>

    <div class="yx-btn" @click="download">免费下载使用</div>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @rightBtnFn="showDialog"
    >
    </common-dialog>
  </div>
</template>
<script>
import commonDialog from "../../../component/dialog/dialog.vue";
import * as animationData from "./yx-gif1.json";
import * as animationData2 from "./yx-gif2.json";
export default {
  name: "yinxiang",
  components: {
    commonDialog,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    let that = this;
    return {
      isShowDialog: false,
      dialogTitle: "获取权限说明",
      showSingleBtn: true,
      isAlignLeft: true,
      dialogContent: "",

      defaultOptions: { animationData: animationData.default },
      defaultOptions2: { animationData: animationData2.default },
      animationSpeed: 1,
      anim: {},
      anim2: {},

      yxUrl: {},
      isIOS: false,

      imgIndex: 0,
      swiperOptions: {
        direction: "vertical",
        effect: "slide",
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false, // 循环模式选项
        initialSlide: 0,
        autoplay: 0,
        navigation: {
          nextEl: ".swiper-button-next", //前进按钮的css选择器或HTML元素。
          prevEl: ".swiper-button-prev", //后退按钮的css选择器或HTML元素。
        },
        on: {
          slideChangeTransitionStart: function () {
            that.imgIndex = this.realIndex; //获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
            console.log(that.imgIndex);
          },
        },
      },
      evalSwiperOptions: {
        autoplay: {
          delay: 3000,
        },
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 30, // 角度调整
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false, //是否开启阴影
        },
      },
    };
  },
  mounted() {
    document.title = "印象证件照";

    this.yxUrl = {
      ios: "https://itunes.apple.com/cn/app/id1445293660",
      android: "https://oss.puwu.info/idphoto/update/印象证件照.apk",
    };
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf("mac os") > -1;

    // let ua = navigator.userAgent.toLowerCase();
    let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    if (!flag) {
      this.$router
        .push({
          name: "yinxiang",
        })
        .catch((err) => {
          err;
        });
    }

    // this.slideTo(6);
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
      this.imgIndex = index;
    },

    download() {
      location.href = this.isIOS ? this.yxUrl.ios : this.yxUrl.android;
    },

    handleAnimation: function (anim) {
      this.anim = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    },
    handleAnimation2: function (anim) {
      this.anim2 = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    },
    iosDownload() {
      location.href = "https://itunes.apple.com/cn/app/id1445293660";
    },
    anzhuoDownload() {
      location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.idphoto";
    },
    showAuthorityBox() {
      this.isShowDialog = true;
      this.dialogContent =
        "为给您提供更优质的服务，印象证件照App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片制作证件照；<br/>2. 相机权限：用于拍摄照片制作证件照；<br/>3. 存储权限： 用于保存制作好的证件照；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
    showDialog() {
      this.isShowDialog = false;
    },
  },
};
</script>
<style lang="less">
// @import "../../../styles/idphoto/app.less";
@import "./yinxiang.less";
</style>