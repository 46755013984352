<template>
  <div class="revideo-wrap">
    <div class="revideo-banner">
      <div class="revideo-banner-left">
        <!-- <div class="revideo-banner-logo"></div> -->
        <div class="revideo-banner-txt pc-show">
          专业可靠的视频/照片修复软件
        </div>
      </div>
    </div>
    <div class="revideo-top-wrap pc-show">
      <div class="revideo-top">
        <div class="revideo-top-pic"></div>
        <div class="revideo-top-txt">
          <div class="revideo-top-title">视频照片恢复清晰</div>
          <div class="revideo-pros">
            <div class="revideo-pros-list">视频智能清晰放大</div>
            <div class="revideo-pros-list">模糊视频秒变清晰</div>
            <div class="revideo-pros-list">破损视频成功播放</div>
          </div>
          <!-- <div class="revideo-top-load">
            <div class="revideo-top-qrcode"></div>
            <div class="revideo-top-btns">
              <div class="revideo-top-btn ios">iOS下载</div>
              <div class="revideo-top-btn">安卓下载</div>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <div class="revideo-content-wrap">
      <div class="revideo-content-list bg2">
        <div class="revideo-list-main">
          <div class="revideo-list-txt">
            <div class="revideo-list-title">视频智能清晰放大</div>
            <div class="revideo-list-pro">
              专业恢复旧照，低像素，网络模糊 图中五官模糊的照片
            </div>
          </div>

          <div class="revideo-list-pic">   
          </div>
        </div>
      </div>

      <div class="revideo-content-list bg3">
        <div class="revideo-list-main">
          <div class="revideo-list-pic bg3">   
          </div>
          <div class="revideo-list-txt fr">
            <div class="revideo-list-title">模糊照片秒变清晰</div>
            <div class="revideo-list-pro pc-show">
              10秒修复模糊照片<br/>让老照片再焕光彩
            </div>
          </div>
      </div>
      <div class="revideo-content-list bg4">
        <div class="revideo-list-main">
          <div class="revideo-list-txt">
            <div class="revideo-list-title">破损视频成功播放</div>
            <div class="revideo-list-pro">极速修复视频至视频成功播放</div>
          </div>
          <div class="revideo-list-pic">  
          </div>
        </div>
      </div>
    </div>

    <div class="revideo-footer">
      <div class="pc-show">
        <div class="copyright">
          Copyright@2020苏州普物科技有限公司
          <a href="http://beian.miit.gov.cn" target="_blank"
            >苏ICP备18052115号—5</a>
        </div>
        <div class="addr">
          地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
        </div>
        <div class="contact">
          电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp;
          邮箱：feedback@techtushuo.com
        </div>
      </div>
      <!-- <div class="phone-show">
        联系电话：0512-87816685
        <br />联系邮箱：feedback@techtushuo.com
        <br />联系地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
        <br />Copyright@2020苏州图说科技有限公司
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "ts_idphoto",
  components: {},
  data() {
    return {
      reNew: {
        oldpic: "",
        newpic: "",
        // oldpic: require("../../../static/imgs/revideo/pic_xiufu.png"),
        // newpic: require("../../../static/imgs/revideo/pic_xiufu2.png"),
        btnLeft: "50%",
        wrapWidth: "100%",
        oldPicWidth: "50%",
        newPicWidth: "50%",
        changeId: "renewId",
      },
      reColor: {
        oldpic: "",
        newpic: "",
        // oldpic: require("../../../static/imgs/revideo/pic_shangse.png"),
        // newpic: require("../../../static/imgs/revideo/pic_shangse2.png"),
        btnLeft: "50%",
        wrapWidth: "100%",
        oldPicWidth: "50%",
        newPicWidth: "50%",
        changeId: "recolorId",
      },

      flags: false,
      position: {
        x: 0,
        y: 0,
      },
      nx: 0,
      dx: 0,
    };
  },
  mounted() {
    // let ua = navigator.userAgent.toLowerCase();
    // if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
    //   let reNewPhoneObj = this.initBtnPos("pic3Wrap");
    //   this.reNew.wrapWidth = reNewPhoneObj.width;
    //   let reColorPhoneObj = this.initBtnPos("pic4Wrap");
    //   this.reColor.wrapWidth = reColorPhoneObj.width;
    // } else {
    //   let reNewObj = this.initBtnPos("pic1Wrap");
    //   this.reNew.wrapWidth = reNewObj.width;
    //   let reColorObj = this.initBtnPos("pic2Wrap");
    //   this.reColor.wrapWidth = reColorObj.width;
    // }
  },
  methods: {
    // initBtnPos(id) {
    //   let maxWidth = document.getElementById(id).offsetWidth;
    //   return {
    //     width: maxWidth,
    //     left: maxWidth / 2 + "px",
    //   };
    // },
    // move(e, obj) {
    //   //获取目标元素
    //   let oDiv = e.target;
    //   //算出鼠标相对元素的位置
    //   let disX = e.clientX - oDiv.offsetLeft;
    //   document.onmousemove = (e) => {
    //     //鼠标按下并移动的事件
    //     //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
    //     let left = e.clientX - disX;
    //     let maxWidth = obj.wrapWidth;
    //     //绑定元素位置到positionX和positionY上面
    //     if (left < 0 || left > maxWidth) return;
    //     //移动当前元素
    //     obj.btnLeft = left + "px";
    //     //修改老图宽度
    //     obj.oldPicWidth = left + "px";
    //     //修改新图宽度
    //     obj.newPicWidth = maxWidth - left + "px";
    //   };
    //   document.onmouseup = (e) => {
    //     document.onmousemove = null;
    //     document.onmouseup = null;
    //   };
    // },
    // clickImg(e, obj) {
    //   let left = e.offsetX;
    //   // 修改拖拽图标位置
    //   obj.btnLeft = left + "px";
    //   //修改老图宽度
    //   obj.oldPicWidth = left + "px";
    //   //修改新图宽度
    //   obj.newPicWidth = obj.wrapWidth - left + "px";
    // },
    // clickReNew(e) {
    //   this.clickImg(e, this.reNew);
    // },
    // clickReColor(e) {
    //   this.clickImg(e, this.reColor);
    // },
    // moveReNew(e) {
    //   this.move(e, this.reNew);
    // },
    // moveReColor(e) {
    //   this.move(e, this.reColor);
    // },
    // down(event, id) {
    //   let moveDiv = document.getElementById(id);
    //   console.log(moveDiv.offsetLeft);
    //   var touch;
    //   if (event.touches) {
    //     touch = event.touches[0];
    //   } else {
    //     touch = event;
    //   }
    //   // console.log(touch.clientX);
    //   this.position.x = touch.clientX;
    //   this.dx = moveDiv.offsetLeft;
    //   this.flags = true;
    // },
    // mobileMove(e, obj) {
    //   if (this.flags) {
    //     let touch;
    //     if (e.touches) {
    //       touch = e.touches[0];
    //     } else {
    //       touch = e;
    //     }
    //     this.nx = touch.clientX - this.position.x;
    //     let left = this.dx + this.nx;
    //     let maxWidth = obj.wrapWidth;
    //     if (left < 0 || left > maxWidth) return;
    //     // 修改拖拽图标位置
    //     obj.btnLeft = left + "px";
    //     //修改老图宽度
    //     obj.oldPicWidth = left + "px";
    //     //修改新图宽度
    //     obj.newPicWidth = obj.wrapWidth - left + "px";
    //   }
    // },
    // end() {
    //   this.flags = false;
    // },
    // 移动端拖拽
    // moveReColorMobile(e) {
    //   this.mobileMove(e, this.reColor);
    // },
    // 移动端拖拽
    // moveReNewMobile(e) {
    //   this.mobileMove(e, this.reNew);
    // },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./revideo.less";
</style>