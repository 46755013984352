<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p class="title"
           style=" line-height:33pt; margin:0pt"><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">24</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">.9元 拍的结婚登记照，</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">对垒网红</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">照相馆一点</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">不</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">怯场</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">最近有个朋友快结婚了，回来跟我抱怨，去了</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">一家网红</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">照相馆拍一张结婚登记照居然要720元。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">震惊，花费720元拍一张登记照，本垃圾感觉捂了捂</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">自己鳖掉的</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">钱包。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">随着颜值至上</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的时代到来，大部分小年轻都愿意花很多钱去拍一张昂贵好看的照片，但还有</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">很大很大</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">一批向小</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">编一样</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的人舍不得花这么多钱去拍一张照片，毕竟，贫穷限制了我人身自由</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="fc87376c-f04e-471e-b4a4-7bdd451e1210.001.jpeg"
               width="255"
               height="255"
               alt="19.9元 拍的结婚登记照，对垒网红照相馆一点不怯场"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">后来跟一个设计师朋友聊起来做结婚登记</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">照价格</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的事，她告诉我，其实有些照相馆拍出来的好看但是会修的过分优化反而失真甚至导致去办证不通过。而且</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">有些人在镜头前放</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">不</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">开拍出来的照片不一定会好看。像于小彤那样在家拍，又有纪念意义，还能自己选片。</span>
        </p>
        <p style=" line-height:21pt; margin:0pt"><img src="fc87376c-f04e-471e-b4a4-7bdd451e1210.002.jpeg"
               width="554"
               height="398"
               alt="19.9元 拍的结婚登记照，对垒网红照相馆一点不怯场"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:21pt; margin:0pt"><img src="fc87376c-f04e-471e-b4a4-7bdd451e1210.003.jpeg"
               width="554"
               height="416"
               alt="19.9元 拍的结婚登记照，对垒网红照相馆一点不怯场"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">针对这些问题，我们聚集了一批专业的设计师用最低的价格专门为用户精修结婚登记照，用户只要上传一张拍摄的比较规范的照片，我们就能精修生成结婚登记照，不仅好看而且符合规范，仅19块9</span>
        </p>
        <p style=" line-height:21pt; margin:0pt"><img src="fc87376c-f04e-471e-b4a4-7bdd451e1210.004.jpeg"
               width="554"
               height="599"
               alt="19.9元 拍的结婚登记照，对垒网红照相馆一点不怯场"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">如果两个人不在一起的还可以上传照片合成结婚登记照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="fc87376c-f04e-471e-b4a4-7bdd451e1210.005.jpeg"
               width="554"
               height="622"
               alt="19.9元 拍的结婚登记照，对垒网红照相馆一点不怯场"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">从开始到现在，我们经常能接到很多小伙伴的留言，“制作的结婚登记照真的很好看，感谢你们，没想到现在在家也能做结婚登记照了，而且效果还这么好。”。“邮寄过来的纸质版效果很好，会推荐给其他人使用”</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">商店也收到了很多好评</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="fc87376c-f04e-471e-b4a4-7bdd451e1210.006.jpeg"
               width="554"
               height="602"
               alt="19.9元 拍的结婚登记照，对垒网红照相馆一点不怯场"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">我们的宗旨是让需要登记照的人都能获得一张合格好看并且有纪念意义的结婚登记照。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="fc87376c-f04e-471e-b4a4-7bdd451e1210.007.jpeg"
               width="554"
               height="254"
               alt="19.9元 拍的结婚登记照，对垒网红照相馆一点不怯场"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:33pt; margin:0pt"><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">&#xa0;</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>
  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>