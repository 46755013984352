<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p style=" line-height:33pt; margin:0pt"><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">如果古人拍结婚照《庆余年》《陈情令》等古装剧最火CP结婚照来啦</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">2019年就要结束啦，在这一年里，有太多精彩的剧在追啦，无论是最近频频</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">上热搜的</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">《庆余年》还是，火遍整个暑假的《陈情令》，等等，都深受好评，但是有一天小</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">编突然</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">想到一个问题，</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">如果古人也拍结婚照，那是什么样子的呢，于是小编做了一份今年热播剧中CP的结婚照，来一起看看吧</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">《庆余年》压阵，庆余年最近可是频频上</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">热搜呀，成爆款</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的原因主要是---因为这部剧的故事请假叙述方式真的太大胆了，。有网友用一句话概括了这个故事：一个向后穿越到未来的留守儿童在豪华</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">陪练团</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">呵护下长大成人，结果他的三个“爸爸”拱手送来了财富、权力、真爱、地位。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="6b6ac68a-fd76-48fa-9016-bf05ca6405da.001.jpeg"
               width="554"
               height="365"
               alt="如果古人拍结婚照《庆余年》《陈情令》等古装剧最火CP结婚照来啦"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">庆余年结婚照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">《鹤唳华亭》</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">小编看了</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">几集，真的是太子被嫌弃的一声，观众除了看着太子被</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">虐</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">，君臣离心，就是一个帝国的沉沦——不管</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">是朝局还是</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">军事上，都没有变好的迹象，朝臣相争仅为自己渔利，但是男主女主的颜值，小</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">编真的</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">吃，画风也深得我心，但是太</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">虐</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">了撑不住</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="6b6ac68a-fd76-48fa-9016-bf05ca6405da.002.jpeg"
               width="554"
               height="365"
               alt="如果古人拍结婚照《庆余年》《陈情令》等古装剧最火CP结婚照来啦"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">鹤唳华亭结婚照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">《东宫》</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">男主与女主为维护两国和平而联姻，就在两人感情渐入佳境时，女</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">主突然</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">回忆起男主杀害自己家人的事情，原来他们二人曾彼此深爱，但却双双落入忘川，忘记了彼此。一边爱情一边权力，做人好难，</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="6b6ac68a-fd76-48fa-9016-bf05ca6405da.003.jpeg"
               width="554"
               height="365"
               alt="如果古人拍结婚照《庆余年》《陈情令》等古装剧最火CP结婚照来啦"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">东宫结婚照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">《宸汐缘》</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">三生三世系列，播出以来好像并没有太大的声音，看过的也反响平，小编并没有看，就不做描述啦，想看的可以起看看~</span>
        </p>
        <p style=" line-height:21pt; margin:0pt"><img src="6b6ac68a-fd76-48fa-9016-bf05ca6405da.004.jpeg"
               width="554"
               height="365"
               alt="如果古人拍结婚照《庆余年》《陈情令》等古装剧最火CP结婚照来啦"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">宸汐</span><span style="color:#999999; font-family:微软雅黑; font-size:9pt">缘结婚照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">《陈情令》这个暑假最火的可能就是魏无</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">羡和蓝忘</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">机的故事，以及因为这个剧大火的肖战与王一博可是办公室小姑娘每个人的男神们</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="6b6ac68a-fd76-48fa-9016-bf05ca6405da.005.jpeg"
               width="554"
               height="365"
               alt="如果古人拍结婚照《庆余年》《陈情令》等古装剧最火CP结婚照来啦"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">陈情令</span><span style="color:#999999; font-family:微软雅黑; font-size:9pt">结婚照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">现在结婚证照片可以自带了哈~ 想在家拍结婚照但是不会P的小伙伴看过来啦</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">我们有专门的设计师可以精修结婚照</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">呦</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>

  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';

export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>