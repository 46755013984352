<template>
  <div class="vr-wrap">
    <div class="vr-header">
      <div class="vr-header-left">视频照片恢复</div>
      <div
        class="vr-header-right tag-read"
        data-clipboard-text="13738817358"
        @click="showKefuTip"
      ></div>
    </div>

    <div class="vr-title-wrap">
      <div class="vr-title">
        <span>视频照片恢复</span>
      </div>
      <div class="vr-title-txt">丢了照片/视频？小智帮你找回</div>
      <div class="vr-title-pic">
        <img src="../../../static/imgs/vr/vr-pic1.png" alt="" />
      </div>
      <div class="vr-title-btn" @click="anzhuoDown"></div>
    </div>

    <div class="vr-pro-intro">产品介绍</div>

    <div class="vr-intro-wrap">
      <div class="vr-intro-list">
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic22.png" alt="" />
        </div>
        <div class="vr-intro-title">
          <div class="vr-intro-name">照片修复</div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
        <div class="vr-intro-txt">照片丢失误删照片找回</div>
      </div>

      <div class="vr-intro-list">
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic33.png" alt="" />
        </div>
        <div class="vr-intro-title">
          <div class="vr-intro-name">视频恢复</div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
        <div class="vr-intro-txt">视频丢失误删视频找回</div>
      </div>

      <div class="vr-intro-list">
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic44.png" alt="" />
        </div>
        <div class="vr-intro-title">
          <div class="vr-intro-name">
            <span>深度照片恢复</span>
            <em>独家</em>
          </div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
        <div class="vr-intro-txt">耗时较长，找回更全面</div>
      </div>

      <div class="vr-intro-list">
        <div class="vr-intro-pic">
          <img src="../../../static/imgs/vr/vr-pic55.png" alt="" />
        </div>
        <div class="vr-intro-title">
          <div class="vr-intro-name">
            <span>深度视频恢复</span>
            <em>独家</em>
          </div>
          <div class="vr-intro-btn" @click="anzhuoDown">立即下载</div>
        </div>
        <div class="vr-intro-txt">耗时较长，找回更全面</div>
      </div>
    </div>

    <div class="vr-footer">
      <p>
        开发者：苏州普物科技有限公司
        <a href="http://beian.miit.gov.cn" target="_blank"
          >苏ICP备18052115号—5</a
        >
      </p>
      <p>
        视频照片恢复 V2.1.8
        <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
          class="blue"
          href="https://oss.puwu.info/restore/web/restore_private.html"
          >隐私协议</a
        >
      </p>
      <!-- <br /> 地址：苏州工业园区脉山龙大厦1号楼415<br />
      邮箱：feegbank@teahpuwu.com&nbsp;&nbsp;电话：0512—827816685 -->
    </div>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :isAlignLeft="isAlignLeft"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      @rightBtnFn="rightBtnFn"
    >
    </common-dialog>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import commonDialog from "../../../component/dialog/dialog.vue";
export default {
  name: "video_restore",
  components: {
    commonDialog,
  },
  data() {
    let that = this;
    return {
      isShowDialog: false,
      dialogContent: "",
      showSingleBtn: true,
      isAlignLeft: false,
      dialogTitle: "温馨提示",
    };
  },
  mounted() {
    // this.isIOS = ua.indexOf('mac os') > -1;
    // this.iosUrl = "https://apps.apple.com/cn/app/id1489463460";
    let ua = navigator.userAgent.toLowerCase();
    let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    if (!flag) {
      this.$router
        .push({
          name: "video_restore",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    showKefuTip() {
      //   this.isShowDialog = true;
      //   this.dialogContent = "微信账号已复制到剪贴板，快去添加客服咨询吧";
      var clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        // alert('微信号复制成功，快去添加微信客服吧')
        this.isShowDialog = true;
        this.isAlignLeft = false;
        this.dialogTitle = "温馨提示";
        this.dialogContent = "微信账号已复制到剪贴板，快去添加客服咨询吧";
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.isShowDialog = true;
        this.isAlignLeft = false;
        this.dialogTitle = "温馨提示";
        this.dialogContent = "该浏览器不支持自动复制";
        // alert('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy();
      });
    },
    rightBtnFn() {
      this.isShowDialog = false;
    },
    anzhuoDown() {
      location.href = "https://oss.puwu.info/restore/update/视频照片恢复.apk";
      //location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.restore";
    },
    showAuthorityBox() {
      this.dialogTitle = "获取权限说明";
      this.isShowDialog = true;
      this.isAlignLeft = true;
      this.dialogContent =
        "为给您提供更优质的服务，视频照片恢复App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于查找您手机里的照片/视频；<br/>2. 存储权限： 用于保存恢复成功的照片/视频；<br/>3. 推送通知权限：用于接收客服回复的消息；<br/>4. 语音输入权限：用于语音咨询客服问题。";
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./vr_index.less";
</style>