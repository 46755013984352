<template>
  <div class="rephoto-v2-wrap swiper-wrapper">
    <swiper ref="mySwiper" :options="rephotoSwiperOptions">
      <swiper-slide>
        <div class="rephoto-v2-bg1">
          <div class="v2-bg1-top">
            <div class="v2-top-logo"></div>
            <div class="v2-top-txt">照片修复<br />全网首家</div>
          </div>
          <div class="v2-bg1-title"></div>
          <div class="v2-bg1-tip">
            修复&nbsp;·&nbsp;上色&nbsp;·&nbsp;翻新&nbsp;·&nbsp;修补&nbsp;·&nbsp;高清
          </div>
          <div class="v2-bg1-pic">
            <img src="../../static/imgs/rephoto_v2/pic_1.png" alt="" />
          </div>
          <div class="v2-bg1-btn" @click="download">立即修复</div>
          <div class="v2-bg1-slider" @click="slideTo(1)"></div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="rephoto-v2-bg bg2">
          <div class="v2-bg-title">资深精修师 人工修复</div>
          <div class="v2-bg-mintitle">高度还原照片·放大冲印也清晰</div>
          <div class="v2-bg-tip">
            对于破损严重的照片，可修复人物五官、皮肤质感，
            画面层次感，高度还原照片原本色彩
          </div>
          <div class="v2-bg2-list">
            <div class="bg2-list-wrap">
              <div class="v2-bg2-box">
                <span>修复前</span>
                <img src="../../static/imgs/rephoto_v2/pic_2_1.png" alt="" />
              </div>
              <div class="v2-bg2-box">
                <span>修复后</span>
                <img src="../../static/imgs/rephoto_v2/pic_2_2.png" alt="" />
              </div>
            </div>
            <div class="bg2-list-wrap">
              <div class="v2-bg2-box">
                <span>修复前</span>
                <img src="../../static/imgs/rephoto_v2/pic_2_3.png" alt="" />
              </div>
              <div class="v2-bg2-box">
                <span>修复后</span>
                <img src="../../static/imgs/rephoto_v2/pic_2_4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="rephoto-v2-bg bg3">
          <div class="v2-bg-title">AI智能修复</div>
          <div class="v2-bg-mintitle">10秒即可修复·人像翻新自然</div>
          <div class="v2-bg-tip">
            对于五官完整的老照片、网络照片、低像素图片
            进行清晰修复，全新专业技术
          </div>
          <div class="v2-bg2-list">
            <div class="bg2-list-wrap">
              <div class="v2-bg2-box">
                <span>修复前</span>
                <img src="../../static/imgs/rephoto_v2/pic_3_1.png" alt="" />
              </div>
              <div class="v2-bg2-box">
                <span>修复后</span>
                <img src="../../static/imgs/rephoto_v2/pic_3_2.png" alt="" />
              </div>
            </div>
            <div class="bg2-list-wrap">
              <div class="v2-bg2-box">
                <span>修复前</span>
                <img src="../../static/imgs/rephoto_v2/pic_3_3.png" alt="" />
              </div>
              <div class="v2-bg2-box">
                <span>修复后</span>
                <img src="../../static/imgs/rephoto_v2/pic_3_4.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="rephoto-v2-bg bg4">
          <div class="v2-bg-title">选择<span>照片修复</span>的理由</div>
          <div class="v2-bg-tip">深耕老照片修复行业，励志修复每一张老照片</div>

          <div class="v2-bg4-main">
            <div class="bg4-main-title">
              <div class="bg4-main-left">
                <span>照片修复</span>
              </div>
              <div class="bg4-main-right">
                <span>其他修复平台</span>
              </div>
            </div>
            <div class="v2-bg4-list">
              <p>资深人工修复团队+高级智能修复团队，倾力修复每一张照片</p>
              <p>
                团队不完善；<br />
                或无团队，做中间商 赚差价
              </p>
              <span>团队</span>
            </div>
            <div class="v2-bg4-list">
              <p>10年技术专业修复老照片<b>好评率高达99%</b></p>
              <p>技术浅薄，制作粗糙<br />无专业技术</p>
              <span>技术</span>
            </div>
            <div class="v2-bg4-list">
              <p><b>首家</b>照片修复软件，源头 修复恢复，价格更亲民</p>
              <p>价格极高，修复粗糙</p>
              <span>价格</span>
            </div>
            <div class="v2-bg4-list">
              <p>专属顾问<b>一对一服务</b>,确保传达每一个修复</p>
              <p>咨询回复不及时<br />修复慢，效果不好</p>
              <span>服务</span>
            </div>
            <div class="v2-bg4-list">
              <p>电话客服/微信客服/在线客服 7*24小时在线答疑</p>
              <p>无售后 图片不满意需付费修改</p>
              <span>售后</span>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="rephoto-v2-bg bg5">
          <div class="v2-bg-title">值得信赖的团队</div>
          <div class="v2-bg-tip">
            人工修复团队和智能修复团队，两个团队成员相辅
            相成，深耕老照片修复专业，励志为每一位需要修
            复老照片的用户修复照片。
          </div>
          <div class="v2-bg5-box">
            <div class="bg5-box-title">人工修复团队</div>
            <div class="bg5-box-tip">源头修复、价格最低，质量最好</div>
            <div class="bg5-box-table">
              <div class="bg5-table-td">
                <p>10年经验</p>
                <p>案例丰富</p>
              </div>
              <div class="bg5-table-td">
                <p>专业团队</p>
                <p>50位修复师</p>
              </div>
              <div class="bg5-table-td">
                <p>平民价格</p>
                <p>服务大众</p>
              </div>
            </div>
            <div class="bg5-box-table">
              <div class="bg5-table-td">
                <p>99%好评</p>
                <p>高度满意</p>
              </div>
              <div class="bg5-table-td">
                <p>专属客服</p>
                <p>一对一服务</p>
              </div>
              <div class="bg5-table-td">
                <p>售后保障</p>
                <p>诚信经营</p>
              </div>
            </div>
          </div>

          <div class="v2-bg5-box ai">
            <div class="bg5-box-title">智能修复团队</div>
            <div class="bg5-box-tip">最先进的技术，修复更真实</div>
            <div class="ai-box-list">
              <div class="ai-box-txt">全国首家智能修复老照片软件</div>
              <div class="ai-box-txt">
                数十名图像识别算法工程师专研5年技术成果
              </div>
              <div class="ai-box-txt">算法强大最先进，修复更真实；快速高效</div>
              <div class="ai-box-txt">快速高效，仅需10秒即可修复成功</div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="rephoto-v2-bg6">
          <div class="v2-bg6-title"><b>人工修复</b>用户评价</div>
          <div class="v2-bg6-tip">
            已人工修复<b>9000+</b>老照片，好评率<b>99%</b>
          </div>
          <div class="v2-bg6-slider">
            <swiper ref="chatSwiper" :options="chatOptions">
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_1.png" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_3.png" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_4.png" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_5.png" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_6.png" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_7.png" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_8.png" alt="" />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="v2-bg6-chat">
                  <img src="../../static/imgs/rephoto_v2/chat_9.png" alt="" />
                </div>
              </swiper-slide>
            </swiper>
          </div>

          <div class="v2-bg6-title">智能修复用户评价</div>
          <div class="v2-bg6-tip">
            已智能修复<b>100,000+</b>老照片，好评率<b>96.5%</b>
          </div>
          <div class="v2-bg6-ai">
            <swiper ref="aiSwiper" :options="aiOptions">
              <swiper-slide>
                <div class="bg6-sliders-details">
                  <div class="bg6-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/1.jpg"
                        alt=""
                      />
                    </p>
                    最美***
                  </div>
                  <div class="bg6-slider-txt">
                    爷爷奶奶的遗照很模糊了 要搬家 还好可以修复 谢谢你们
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg6-sliders-details">
                  <div class="bg6-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/2.jpg"
                        alt=""
                      />
                    </p>
                    落日的***
                  </div>
                  <div class="bg6-slider-txt">
                    我长期买金币的 我开照相馆的 给顾客清晰 挺方便
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg6-sliders-details">
                  <div class="bg6-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/3.jpg"
                        alt=""
                      />
                    </p>
                    邹欣***
                  </div>
                  <div class="bg6-slider-txt">
                    人工帮我修了一张爷爷年轻时的老照片 太好了 爷爷高兴坏了
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg6-sliders-details">
                  <div class="bg6-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/4.jpg"
                        alt=""
                      />
                    </p>
                    哈哈***
                  </div>
                  <div class="bg6-slider-txt">修的真清晰 ai太强大了</div>
                </div>
              </swiper-slide>
            </swiper>

            <div class="bg6-footer-txt">
              <p>
                开发者：苏州普物科技有限公司<a
                  href="http://beian.miit.gov.cn"
                  target="_blank"
                  >苏ICP备18052115号-4</a
                >
              </p>
              <p>
                照片修复 V2.1.8
                <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
                  class="blue"
                  href="https://oss.puwu.info/rephoto/web/privacy_oldrephoto.html"
                  >隐私协议</a
                >
              </p>
              <!-- <p>
                地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
              </p>
              <p>邮箱：feedback@techpuwu.com &nbsp; 电话：0512-87816685</p> -->
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <div class="v2-bg-btns" v-if="sliderIndex">
      <div
        class="v2-bg-btn tag-read"
        data-clipboard-text="13738817358"
        @click="getWxMsg"
      >
        微信咨询
      </div>
      <div class="v2-bg-btn" @click="download">立即修复</div>
    </div>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :leftBtnTxt="leftBtnTxt"
      :rightBtnTxt="rightBtnTxt"
      :showDoubleBtn="showDoubleBtn"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @leftBtnFn="leftBtnFn"
      @rightBtnFn="rightBtnFn"
    >
    </common-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import commonDialog from "../../component/dialog/dialog.vue";
export default {
  name: "rephoto-v2",
  components: {
    commonDialog,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    let that = this;
    return {
      isShowDialog: false,
      dialogTitle: "微信号复制成功",
      dialogContent: "打开微信—添加好友—长按粘贴微信号即可添加",
      showDoubleBtn: true,
      leftBtnTxt: "去添加好友",
      rightBtnTxt: "去App了解更多",
      showSingleBtn: false,
      isAlignLeft: false,

      sliderIndex: 0,
      rephotoSwiperOptions: {
        direction: "vertical",
        effect: "slide",
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false, // 循环模式选项
        initialSlide: 0,
        autoplay: 0,
        navigation: {
          nextEl: ".swiper-button-next", //前进按钮的css选择器或HTML元素。
          prevEl: ".swiper-button-prev", //后退按钮的css选择器或HTML元素。
        },
        on: {
          slideChangeTransitionStart: function () {
            that.sliderIndex = this.realIndex; //获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
            // console.log(that.sliderIndex);
          },
        },
      },
      chatOptions: {
        autoplay: {
          delay: 3000,
        },
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 30, // 角度调整
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false, //是否开启阴影
        },
      },
      aiOptions: {
        autoplay: {
          delay: 3000,
        },
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 30, // 角度调整
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false, //是否开启阴影
        },
      },

      isIOS: false,
      iosUrl: "https://apps.apple.com/cn/app/id1497453325",
      anzhuoUrl: "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.rephoto",
      anzhuoApk: "https://oss.puwu.info/rephoto/update/照片修复.apk",
    };
  },
  mounted() {
    document.title = "照片修复";

    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf("mac os") > -1;

    let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    if (!flag) {
      this.$router
        .push({
          name: "home",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
      this.sliderIndex = index;
    },

    download() {
      let goUrl = this.isIOS ? this.iosUrl : this.anzhuoApk;
      window.open(goUrl, "_blank");
    },
    leftBtnFn() {
      this.isShowDialog = false;
    },
    rightBtnFn() {
      this.isShowDialog = false;
      if (this.showDoubleBtn) {
        this.download();
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    },
    handleAnimation2: function (anim) {
      this.anim2 = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    },
    iosDownload() {
      location.href = "https://itunes.apple.com/cn/app/id1445293660";
    },
    anzhuoDownload() {
      location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.idphoto";
    },
    getWxMsg() {
      let clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        // alert('微信号复制成功，快去添加微信客服吧')
        this.dialogTitle = "微信号复制成功";
        this.isShowDialog = true;
        this.showDoubleBtn = true;
        this.showSingleBtn = false;
        this.isAlignLeft = false;
        this.dialogContent = "打开微信—添加好友—长按粘贴微信号即可添加";
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.isShowDialog = true;
        this.dialogTitle = "温馨提示";
        this.dialogContent = "该浏览器不支持自动复制";
        this.showDoubleBtn = false;
        this.showSingleBtn = true;
        this.isAlignLeft = false;
        // alert('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy();
        // e.preventDefault();
      });
    },
    showAuthorityBox() {
      this.dialogTitle = "获取权限说明";
      this.isShowDialog = true;
      this.showDoubleBtn = false;
      this.showSingleBtn = true;
      this.isAlignLeft = true;
      this.dialogContent =
        "为给您提供更优质的服务，照片修复App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片进行修复/上色；<br/>2. 相机权限：用于拍摄照片进行修复/上色；<br/>3. 存储权限： 用于保存修复/上色成功的照片；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
  },
};
</script>
<style lang="less">
@import "./rephoto.less";
</style>