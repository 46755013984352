<template>
  <div>
    <!-- 公共导航 -->
    <idphoto-header></idphoto-header>

    <div class="banner c">
      <img src="../../../static/imgs/idphoto/m_banner@2x.png"
           alt="" />
    </div>

    <div class="content">

      <!-- <div class="info-item">
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031716511148')">
          <img src="../artical/031716511148.jpeg"
               alt="" />
          <div class="info-detail">
            <h1>《手机拍结婚照》省下去照相馆的钱，还很好看</h1>
            <p>
              在这个看脸颜值至上的时代，每一对新人的结婚登记照都应该充满满满的仪式感还好困，做朋友圈最好看的崽~
            </p>
          </div>
        </a>
      </div>
      <div class="info-item">
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031717272818')">
          <img src="../artical/031717272818.jpeg"
               alt="" />
          <div class="info-detail">
            <h1>24.9元 拍的结婚登记照，对垒网红照相馆一点不怯场</h1>
            <p>
              最近有个朋友快结婚了，回来跟我抱怨，去了一家网红照相馆拍一张结婚登记照居然要720元。
            </p>
          </div>
        </a>
      </div>
      <div class="info-item">
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031717515628')">
          <img src="../artical/031717515628.jpeg"
               alt="" />
          <div class="info-detail">
            <h1>2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看</h1>
            <p>
              2020年3月各个省份的计算机二级考试报名时间出来啦，相信大家流程上都了解得差不多了，但是每年总会有一些小伙伴拍摄的报名照不合格导致耽误了报名时间，今天小编就来教大家快速拍一张好看又标准的计算机报名照。
            </p>
          </div>
        </a>
      </div>
      <div class="info-item">
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031718575701')">
          <img src="../artical/031718575701.jpeg"
               alt="" />
          <div class="info-detail">
            <h1>备婚小情侣们，宅家也能拍好结婚证照片</h1>
            <p>
              今年2月2日，因完整写法为“20200202”，被称为“完全对称日”。2月2日是农历正月初九，又寓意长长久久，加上其谐音为“爱你爱你”，被网友称为“最佳领证日”。本来是周日，但是部分城市民政局考虑到这天年轻人领证的急切愿望，就开放上班办理登记手续。不过因为疫情的影响，很多民政局都放弃了办理。
            </p>
          </div>
        </a>
      </div>
      <div class="info-item">
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031719060900')">
          <img src="../artical/031719060900.jpeg"
               alt="" />
          <div class="info-detail">
            <h1>奔走相告，结婚证件照app上线啦</h1>
            <p>
              奔走相告，我们策划已久的结婚证件照app终于上线啦。数十位专业人物精修设计师在线修图，确保每张照片都精美好看。只要你上传一张普通的合照，我们就能帮您制作一张好看的结婚证件照。为那些在照相馆拍照表情不自然、介意价格昂贵、天气原因不想出门等等困境一扫而光，花白菜的价做最好看的结婚登记照。
            </p>
          </div>
        </a>
      </div>
      <div class="info-item">
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031809512218')">
          <img src="../artical/031809512218.jpeg"
               alt="" />
          <div class="info-detail">
            <h1>隔离在家，为了情人节领证，异地恋拍结婚登记照的我们选择这么拍</h1>
            <p>
              隔离在家，希望疫情早点过去，年前计划情人节那天去跟对象领证，没想到隔离在家不能出门，领证的日子从202020202的对称日延期到2月14的正常情人节，没想到居然一拖就拖到了今年的白色情人节。
            </p>
          </div>
        </a>
      </div>
      <div class="info-item">
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031809530398')">
          <img src="../artical/031809530398.png"
               alt="" />
          <div class="info-detail">
            <h1>哈士奇结婚证件照你见过吗？</h1>
            <p>
              都说哈士奇是上帝画狼时打的草稿，有美貌但二，小编只想默默说一句，上帝是公平的。
            </p>
          </div>
        </a>
      </div>
      <div class="info-item"> 
        <a class="artical"
           target="_blank"
           @click="jumpArtical('031809560240')">
          <img src="../artical/031809560240.jpeg"
               alt="" />
          <div class="info-detail">
            <h1>如果古人拍结婚照《庆余年》《陈情令》等古装剧最火CP结婚照来啦</h1>
            <p>
              隔离在家，希望疫情早点过去，年前计划情人节那天去跟对象领证，没想到隔离在家不能出门，领证的日子从202020202的对称日延期到2月14的正常情人节，没想到居然一拖就拖到了今年的白色情人节。
            </p>
          </div>
        </a>
      </div>-->

      <div class="info-item"
           v-for="(item,index) in articalList"
           :key="index"
           @click="jumpArtical(item.id)">
        <img :src=imgsList[index]
             alt="" />
        <div class="info-detail">
          <h1>{{item.title}}</h1>
          <p>
            {{item.intro}}
          </p>
        </div>
      </div>

      <div class="info-item-clear">
      </div>

      <!-- <div class="pages flex_box flex_align_center flex_justify_center">
                <div class="pre">上一页</div>
                <div class="page active">1</div>
                <div class="page">2</div>
                <div class="next">下一页</div>
            </div> -->
    </div>

    <!-- 公共底部 -->
    <idphoto-footer></idphoto-footer>
  </div>
</template>
<script>
import articalList from '../../../static/json/artical.json'
import IdphotoHeader from '../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../component/idphoto/footer/footer.vue';
export default {
  name: 'zixun',
  components: {
    IdphotoHeader,
    IdphotoFooter
  },
  data () {
    return {
      articalList: articalList,
      imgsList: [
        require('../artical/031716511148.jpeg'),
        require('../artical/031717272818.jpeg'),
        require('../artical/031717515628.jpeg'),
        require('../artical/031718575701.jpeg'),
        require('../artical/031719060900.jpeg'),
        require('../artical/031809512218.jpeg'),
        require('../artical/031809530398.png'),
        require('../artical/031809560240.jpeg'),
      ],
    }
  },
  mounted () {
    document.title = "证件照";
  },
  methods: {
    jumpArtical (id) {
      this.$router.push({
        name: 'artical/' + id
      }).catch(err => { err })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/app.less";
@import "./zixun.less";
</style>