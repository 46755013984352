<template>
  <div class="photo-wrap">
    <div class="photo-center photo-header">
      <!-- logo -->
      <h2></h2>
      <!-- pc导航 -->
      <div class="header-bar">
        <span :class="{'on':urlType=='home'}"
              @click="jumpUrl(1)">产品简介</span>
        <span :class="{'on':urlType=='cases'}"
              @click="jumpUrl(2)">修复案例</span>
        <span :class="{'on':urlType=='news' || urlType=='newslist'}"
              @click="jumpUrl(3)"
              style="display:none">文章资讯</span>
        <span :class="{'on':urlType=='about'}"
              @click="jumpUrl(4)">联系我们</span>
      </div>
      <!-- 移动导航 -->
      <div class="phone-bar"
           @click="showNavBar()">
      </div>

      <div class="phone-bar-layer phone-show"
           :class="{'show':showBar}"
           @click="hideNavBar()">

        <div class="phone-bar-main">
          <div class="layer-close"></div>
          <br class="clear" />
          <span :class="{'on':urlType=='m_home'}"
                @click="jumpUrl(5)">产品简介</span>
          <span :class="{'on':urlType=='cases'}"
                @click="jumpUrl(2)">修复案例</span>
          <span :class="{'on':urlType=='news' || urlType=='newslist'}"
                @click="jumpUrl(3)"
                style="display:none">文章资讯</span>
          <span :class="{'on':urlType=='about'}"
                @click="jumpUrl(4)">联系我们</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'photo-header',
  data () {
    return {
      urlType: '',
      showBar: false,
    }
  },
  mounted () {
    this.urlType = this.$route.name;
    this.isAbout = this.$route.name == 'about';

    let ua = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad|phone/i.test(ua) && this.urlType.indexOf('home') >= 0) {
      this.$router.push({
        name: 'm_home'
      }).catch(err => { err })
    }
  },
  methods: {
    // _isMobile () {
    //   let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //   return flag;
    // },
    showNavBar () {
      this.showBar = true;
      //   document.getElementById('box').style.overflow = 'hidden';
    },
    hideNavBar () {
      this.showBar = false;
      //   document.getElementById('box').style.overflow = 'auto';
    },
    jumpUrl (type) {
      let index = type - 1;
      let names = ['home', 'cases', 'news', 'about', 'm_home'];
      this.$router.push({
        name: names[index]
      }).catch(err => { err })



      //       let ua = navigator.userAgent.toLowerCase();
      // let isIOS = ua.indexOf('mac os') > -1;
      // let iosUrl = "https://apps.apple.com/cn/app/id1489463460";

      // document.querySelector(".ios").onclick = () => {
      //     location.href = iosUrl;
      // }

      // document.querySelector(".anzhuo").onclick = () => {
      //     location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.onephoto";
      // }

      // document.querySelector(".apk-btn-dw").onclick = () => {
      //     location.href = isIOS ? iosUrl : "https://oss.puwu.info/tphoto/update/证件照相馆.apk";
      // }

    },
  },
  watch: {
    $route (newValue, value) {
    },
  }
}
</script>
<style lang="less" scoped>
@import "./header.less";
</style>