<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p class="title"
           style=" line-height:33pt; margin:0pt"><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">备婚小</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">情侣们，</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">宅家也能</span><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">拍好结婚证照片</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">今年2月2日，因完整写法为“20200202”，被称为“完全对称日”。2月2日是农历正月初九，又寓意长长久久，加上其谐音为“爱你爱你”，被网友称为“最佳领证日”。本来是周日，但是部分城市民政局考虑到这天年轻人领证的急切愿望，就开放上班办理登记手续。不过因为疫情的影响，很多民政局都放弃了办理。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">遗憾肯定难免，不过</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">跟安全比</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">起来还是乖乖听国家的话，不给组织添麻烦。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="a8d8e014-fa12-49a9-8de3-805c40899221.001.jpeg"
               width="522"
               height="746"
               alt="备婚小情侣们，宅家也能拍好结婚证照片"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">宅在家用手机拍</span><span style="color:#999999; font-family:微软雅黑; font-size:9pt">结婚登记照</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">马上就要到情人节了，既然已经错过了对称日，就不要在错过情人节了，毕竟到2月14号，相信疫情有</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">很大很大</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">的好转。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">但是不能出门，照相馆不开门，要怎么提前备好好看的结婚登记证照片呢？你可以在家拍呀！
            现在就教大家怎么在家自拍结婚登记照咯！</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="a8d8e014-fa12-49a9-8de3-805c40899221.002.jpeg"
               width="554"
               height="554"
               alt="备婚小情侣们，宅家也能拍好结婚证照片"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">小可爱们关注结婚证件照app呀</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">关于拍照，你需要准备：</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">1.画个淡妆（建议穿白衬衫呀）</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">2拍摄设备（手机+拍摄人，相机+三脚架）</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">3一面背景墙（大白墙好评）</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">拍摄过程：直接按平时的拍摄就行啦，建议多拍摄很多张，正式领证用的，和一组各种表情搞</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">怪照片</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">用来发票圈。动作姿势可以参考黄晓明哈。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="a8d8e014-fa12-49a9-8de3-805c40899221.003.jpeg"
               width="554"
               height="501"
               alt="备婚小情侣们，宅家也能拍好结婚证照片"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">结婚证件</span><span style="color:#999999; font-family:微软雅黑; font-size:9pt">照朋友圈发图</span><span style="color:#999999; font-family:微软雅黑; font-size:9pt">姿势大全</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">关于修图，你仅仅需要--一个</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt; font-weight:bold">【结婚证件照】</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="a8d8e014-fa12-49a9-8de3-805c40899221.004.jpeg"
               width="554"
               height="626"
               alt="备婚小情侣们，宅家也能拍好结婚证照片"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">结婚证件照app截图</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">上面有专业的设计师在线修图，</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">抠图修图</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">，一条龙服务，需要纸质版可以全国包邮，支持普通快递和加急快递两种。极为方便。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="a8d8e014-fa12-49a9-8de3-805c40899221.005.jpeg"
               width="554"
               height="873"
               alt="备婚小情侣们，宅家也能拍好结婚证照片"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="a8d8e014-fa12-49a9-8de3-805c40899221.006.jpeg"
               width="554"
               height="873"
               alt="备婚小情侣们，宅家也能拍好结婚证照片"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">需要的话可以试试哇</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>
  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>