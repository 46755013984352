<template>
  <div class="jiehun-v2-wrap">
    <!-- 第一屏 -->

    <div class="jiehun-v2-bg1">
      <div class="v2-bg1-top">
        <div class="v2-top-logo"></div>
        <div class="v2-top-txt">结婚证件照App</div>
      </div>
      <div class="v2-bg1-title"></div>
      <div class="v2-bg1-tip">给你一张满意的结婚登记照</div>
      <div class="v2-bg1-pic">
        <img src="../../../static/imgs/idphoto-v2/jh/pic2.png" alt="" />
      </div>
      <div class="v2-bg1-btn" @click="download">立即制作</div>
      <!-- <div class="v2-bg1-slider" @click="slideTo(1)"></div> -->
      <div class="jiehun-quanxian">
        <p>【 V2.1.9 版本 · 软件大小 23MB 】</p>
        <p>开发者：苏州普物科技有限公司</p>
        <p>
          <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;|&nbsp;<a
            class="blue"
            href="https://oss.puwu.info/mphoto/web/privacy_marryphoto.html"
            >隐私协议</a
          >
        </p>
      </div>
    </div>

    <!-- 第二屏 -->

    <div class="jiehun-wrap-bg bg2">
      <div class="jiehun-bg-title">最适合你的<span>结婚证件照</span></div>
      <div class="jiehun-bg-tip">
        高级精修师人工精修，确保每一张结婚登记照合格又好看；智能生成结婚登记照，AI美颜算法打造自然裸妆感，让你快速拥有一张好看的照片。
      </div>
      <div class="jiehun-table">
        <div class="jiehun-table-th">
          <div class="jiehun-table-td">
            <img
              src="../../../static/imgs/idphoto-v2/jh/ic_gou.png"
              alt=""
            />结婚证件照
          </div>
          <div class="jiehun-table-td">
            <img
              src="../../../static/imgs/idphoto-v2/jh/ic_cha.png"
              alt=""
            />普通证件照
          </div>
        </div>
        <div class="jiehun-table-tr">
          <div class="jiehun-table-td">
            <img src="../../../static/imgs/idphoto-v2/jh/pic2_1.png" alt="" />
            <p>智能生成，自带美颜<br />高清人像，照片更专业</p>
          </div>
          <div class="jiehun-table-td">
            <img src="../../../static/imgs/idphoto-v2/jh/pic2_2.png" alt="" />
            <p>抠图不精准<br />传统美颜算法，不真实</p>
          </div>
        </div>
        <div class="jiehun-table-tr">
          <div class="jiehun-table-td">
            <p>高级修图师<span class="red">人工精修</span></p>
          </div>
          <div class="jiehun-table-td">
            <p class="red">不支持</p>
          </div>
        </div>
        <div class="jiehun-table-tr">
          <div class="jiehun-table-td">
            <p class="red">单人照合成</p>
          </div>
          <div class="jiehun-table-td"><p class="red">不支持</p></div>
        </div>
        <div class="jiehun-table-tr">
          <div class="jiehun-table-td"><p>满意度高达99%</p></div>
          <div class="jiehun-table-td"><p>体验不佳，无售后</p></div>
        </div>
        <div class="jiehun-table-tr">
          <div class="jiehun-table-td"><p>人工客服24h应答</p></div>
          <div class="jiehun-table-td"><p>无客服处理，或处理不及</p></div>
        </div>
      </div>
    </div>

    <!-- 第三屏 -->

    <div class="jiehun-wrap-bg bg3">
      <div class="jiehun-bg-title"><span>仅需10秒</span></div>
      <div class="jiehun-bg-mintitle">智能生成最美结婚证件照</div>
      <div class="jiehun-bg-tip">
        只需拍摄/上传照片，系统即可自动生成<br />美颜算法加持，轻松自拍出最美正件照
      </div>
      <div class="jiehun-bg3-gif">
        <lottie :options="defaultOptions" :width="320" :height="214" />
      </div>
      <div class="jiehun-tip-wrap">
        <div class="jiehun-bg-step">Step1</div>
        <div class="jiehun-bg-tip">拍摄/上传双人正面无遮挡的照片，系统抠图</div>
        <div class="jiehun-bg-step">Step2</div>
        <div class="jiehun-bg-tip">调整背景底色、美颜程度，清晰度</div>
        <div class="jiehun-bg-step">Step3</div>
        <div class="jiehun-bg-tip">一键保存到相册</div>
      </div>
    </div>

    <!-- 第四屏 -->

    <div class="jiehun-wrap-bg bg4">
      <div class="jiehun-bg-title">双人照<span>人工精修</span></div>
      <div class="jiehun-bg-tip">
        高端精修五官，调整任务比例、站位<br />独家人像高清技术，普通照片打造专业及质感
      </div>
      <div class="jiehun-slider-box">
        <swiper ref="jiehunSwiper" :options="jiehunSwiperOptions">
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic4_1.png" alt="" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic4_2.png" alt="" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic4_3.png" alt="" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic4_4.png" alt="" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination2" slot="pagination"></div>
        </swiper>
      </div>
    </div>

    <!-- 第五屏 -->

    <div class="jiehun-wrap-bg bg4 bg5">
      <div class="jiehun-bg-title">单人照<span>人工合成</span></div>
      <div class="jiehun-bg-tip">
        高端无痕合成，完美融合背景<br />调整统一肤色、光线、服装，合成更真实
      </div>
      <div class="jiehun-slider-box">
        <swiper ref="jiehunSwiper" :options="jiehunSwiperOptions">
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic5_1.png" alt="" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic5_2.png" alt="" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic5_3.png" alt="" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="jiehun-bg-list">
              <img src="../../../static/imgs/idphoto-v2/jh/pic5_4.png" alt="" />
            </div>
          </swiper-slide>
          <div class="swiper-pagination2" slot="pagination"></div>
        </swiper>
      </div>
    </div>

    <!-- 第六屏 -->

    <div class="jiehun-wrap-bg bg6">
      <div class="jiehun-bg-title"><span>人工精修</span>结婚登记照</div>
      <div class="jiehun-bg-tip">
        专业人像修图师在线精修，多年影楼后期经验<br />
        2小时即可拿图，可反复修改，包您满意
      </div>
      <div class="jiehun-bg6-pic">
        <img src="../../../static/imgs/idphoto-v2/jh/pic6_1.png" alt="" />
      </div>
      <div class="jiehun-bg6-pic2">
        <img src="../../../static/imgs/idphoto-v2/jh/pic6_2.png" alt="" />
      </div>
    </div>

    <!-- 第七屏 -->

    <div class="jiehun-wrap-bg bg7">
      <div class="jiehun-bg-title">好评如潮 深受信赖</div>
      <div class="jiehun-bg-tip">
        已成功为<span class="red">40000+</span> 对新人制作结婚登记照
      </div>
      <div class="jiehun-bg7-pic">
        <img src="../../../static/imgs/idphoto-v2/jh/pic7.png" alt="" />
      </div>
      <div class="bg7-footer-txt">
        <p>
          开发者：苏州普物科技有限公司&nbsp;<a
            href="http://beian.miit.gov.cn"
            target="_blank"
            >苏ICP备18052115号-6</a
          >
        </p>
        <p>
          结婚证件照 V2.1.9
          <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
            class="blue"
            href="https://oss.puwu.info/mphoto/web/privacy_marryphoto.html"
            >隐私协议</a
          >
        </p>
        <!-- <p>
          地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
        </p>
        <p>邮箱：feedback@techpuwu.com &nbsp; 电话：0512-87816685</p> -->
      </div>
    </div>

    <div class="swiper-pagination" slot="pagination"></div>

    <div class="jiehun-v2-btn" @click="download">立即使用</div>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @rightBtnFn="showDialog"
    >
    </common-dialog>
  </div>
</template>

<script>
// import Clipboard from "clipboard";
import commonDialog from "../../../component/dialog/dialog.vue";
import * as animationData from "./jiehun.json";
export default {
  name: "jiehun",
  components: {
    commonDialog,
  },
  computed: {
    swiper() {
      return this.$refs.jiehunSwiperBg.$swiper;
    },
  },
  data() {
    let that = this;
    return {
      isShowDialog: false,
      dialogTitle: "获取权限说明",
      showSingleBtn: true,
      isAlignLeft: true,
      dialogContent: "",

      defaultOptions: { animationData: animationData.default },
      sliderIndex: 0,

      jiehunSwiperOptions: {
        direction: "horizontal",
        effect: "slide",
        pagination: {
          el: ".swiper-pagination2",
        },
        loop: true, // 循环模式选项
        initialSlide: 0,
        autoplay: { delay: 3000 },
      },

      jiehunSwiperBgOptions: {
        direction: "vertical",
        effect: "slide",
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false, // 循环模式选项
        initialSlide: 0,
        autoplay: 0,
        navigation: {
          nextEl: ".swiper-button-next", //前进按钮的css选择器或HTML元素。
          prevEl: ".swiper-button-prev", //后退按钮的css选择器或HTML元素。
        },
        on: {
          slideChangeTransitionStart: function () {
            that.sliderIndex = this.realIndex; //获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
            // console.log(that.sliderIndex);
          },
        },
      },

      isIOS: false,
    };
  },
  mounted() {
    document.title = "结婚证件照";

    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf("mac os") > -1;

    let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    if (!flag) {
      this.$router
        .push({
          name: "jiehun",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
      this.sliderIndex = index;
    },
    download() {
      let iosUrl = "https://apps.apple.com/cn/app/id1489463460";
      let anzhuoApk =
        "https://oss.puwu.info/tphoto/update/最美证件照专业版.apk";
      let goUrl = this.isIOS ? iosUrl : anzhuoApk;
      window.open(goUrl, "_blank");
    },
    download() {
      let jiehunUrl = {
        ios: "https://apps.apple.com/cn/app/id1484296045",
        android: "https://oss.puwu.info/mphoto/结婚证件照.apk",
      };
      location.href = this.isIOS ? jiehunUrl.ios : jiehunUrl.android;
    },
    showAuthorityBox() {
      this.isShowDialog = true;
      this.dialogContent =
        "为给您提供更优质的服务，结婚证件照App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片制作证件照；2. 相机权限：用于拍摄照片制作证件照；<br/>3. 存储权限： 用于保存制作好的证件照；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
    showDialog() {
      this.isShowDialog = false;
    },
  },
};
</script>
<style lang="less">
@import "./jiehun.less";
</style>