<template>
  <div>
    <!-- 公用头部 -->
    <photo-header></photo-header>

    <div class="photo-case-wrap">
      <h3>修复案例</h3>

      <div class="case-list-wrap">

        <div class="case-list"
             v-for="(item,index) in caseList"
             :key="index">

          <div class="case-pic">
            <div class="old">
              <img :src=item.oldUrl
                   alt="原图">
              <span>原图</span>
            </div>
            <div class="new">
              <img :src=item.newUrl
                   alt="原图">
              <span>修复后</span>
            </div>
          </div>

          <div class="case-price">
            <span>{{item.title}}</span>
            <span>修复价格{{item.price}} 耗时{{item.time}}</span>
          </div>

        </div>

      </div>
    </div>

    <!-- 公用底部 -->
    <photo-footer></photo-footer>
  </div>
</template>
<script>
import PhotoHeader from '../../../component/rephoto/header/header.vue';
import PhotoFooter from '../../../component/rephoto/footer/footer.vue';
// import PhotoHeader from '../header/header.vue';
// import PhotoFooter from '../footer/footer.vue';
// import data from '../../static/json/case.json'
export default {
  name: 'cases',
  components: {
    PhotoHeader,
    PhotoFooter,
  },
  data () {
    return {
      caseList: [{
        "title": "初级修复",
        "price": "¥60起",
        "time": "1-2天",
        "oldUrl": require("../../../static/imgs/rephoto/case/low_1_1.jpg"),
        "newUrl": require("../../../static/imgs/rephoto/case/low_1_2.jpg")
      }, {
        "title": "中级修复",
        "price": "¥158起",
        "time": "1-3天",
        "oldUrl": require("../../../static/imgs/rephoto/case/low_2_1.jpg"),
        "newUrl": require("../../../static/imgs/rephoto/case/low_2_2.jpg"),
      }, {
        "title": "中级修复",
        "price": "¥158起",
        "time": "1-3天",
        "oldUrl": require("../../../static/imgs/rephoto/case/high_2_1.jpg"),
        "newUrl": require("../../../static/imgs/rephoto/case/high_2_2.jpg"),
      }, {
        "title": "中级修复",
        "price": "¥158起",
        "time": "1-3天",
        "oldUrl": require("../../../static/imgs/rephoto/case/mid_2_1.jpg"),
        "newUrl": require("../../../static/imgs/rephoto/case/mid_2_2.jpg"),
      }, {
        "title": "高级修复",
        "price": "¥238起",
        "time": "1-5天",
        "oldUrl": require("../../../static/imgs/rephoto/case/mid_1_1.jpg"),
        "newUrl": require("../../../static/imgs/rephoto/case/mid_1_2.jpg"),
      }, {
        "title": "高级修复",
        "price": "¥238起",
        "time": "1-5天",
        "oldUrl": require("../../../static/imgs/rephoto/case/high_1_1.jpg"),
        "newUrl": require("../../../static/imgs/rephoto/case/high_1_2.jpg"),
      }]
    }
  },
  mounted () {
  },
}
</script>
<style lang="less" scoped>
@import "./case.less";
</style>