<template>
  <div class="service-wrap">
    <div class="service-header-wrap">
      <div class="service-header">
        <img class="w15" src="../../static/imgs/service/logo.png" alt />
        <img class="w65" src="../../static/imgs/service/logo-txt.png" alt />
        <b></b>
        <img class="w123" src="../../static/imgs/service/logo-txt-en.png" alt />
      </div>
    </div>

    <div class="service-top-wrap">
      <div class="service-top">
        <div class="service-top-title">百鸟客服</div>
        <div class="service-top-txt">
          全场景智能客服系统
          <br />让咨询更简单，快速沟通链接未来
        </div>
        <!-- <div class="service-top-btn">立即咨询</div> -->
      </div>
    </div>

    <div class="service-block-wrap">
      <div class="service-block">
        <div class="service-block-intro fr">
          <div class="service-block-title">一键接入 随时随地 轻松沟通</div>
          <div class="service-block-txt">电脑、手机记录同步、随时随地快速应答</div>
          <div class="service-block-txt">支持语音、图片、视频发送，提高服务质量</div>
        </div>
        <div class="service-block-pic fl">
          <img class="w123" src="../../static/imgs/service/pic_1.png" alt />
        </div>
      </div>
    </div>

    <div class="service-block-wrap bg-color">
      <div class="service-block">
        <div class="service-block-intro">
          <div class="service-block-title">智能回复 快速响应</div>
          <div class="service-block-txt">智能机器人配合人工服务，高效应答</div>
          <div class="service-block-txt">知识库资源整合，助力每一次沟通</div>
        </div>
        <div class="service-block-pic">
          <img class="w123" src="../../static/imgs/service/pic_2.png" alt />
        </div>
      </div>
    </div>

    <div class="service-block-wrap">
      <div class="service-block">
        <div class="service-block-intro fr">
          <div class="service-block-title">服务稳健 消息收发安全</div>
          <div class="service-block-txt">强大的技术支持，确保消息收发稳定</div>
          <div class="service-block-txt">独家加密技术，确保信息安全，保护隐私</div>
        </div>
        <div class="service-block-pic fl">
          <img class="w123" src="../../static/imgs/service/pic_3.png" alt />
        </div>
      </div>
    </div>

    <div class="service-block-wrap bg-color">
      <div class="service-block">
        <div class="service-block-intro">
          <div class="service-block-title">对接用户信息 让服务更懂用户</div>
          <div class="service-block-txt">对接用户信息&订单信息接口，根据用户合理</div>
          <div class="service-block-txt">分配对接客服，快速解决用户问题。</div>
        </div>
        <div class="service-block-pic">
          <img class="w123" src="../../static/imgs/service/pic_4.png" alt />
        </div>
      </div>
    </div>

    <div class="service-footer-wrap">
      <div class="service-footer-main">
        联系电话：0512—87816685
        <br />联系邮箱：feedback@techpuwu.com
        <br />联系地址：江苏省苏州市工业园区裕新路168号脉山龙大厦1号楼420室
        <br />copyright@2018苏州普物科技有限公司
        <a
          href="http://beian.miit.gov.cn"
          target="_blank"
        >苏ICP备18052115号—7</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "chat",
  data() {
    return {};
  },
  mounted() {
    // let ua = navigator.userAgent.toLowerCase();
    // if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
    //   this.$router
    //     .push({
    //       name: "m_video_restore",
    //     })
    //     .catch((err) => {
    //       err;
    //     });
    // }
  },
  methods: {
    anzhuoDown() {
      //   location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo";
      window.open("https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.restore");
    },
  },
};
</script>
<style lang="less" scoped>
@import "./customer_service.less";
</style>