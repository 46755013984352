<template>
  <div>

    <!-- 公共导航 -->
    <div v-if="isMobile">
      <idphoto-mheader></idphoto-mheader>
    </div>
    <div v-else>
      <idphoto-header></idphoto-header>
    </div>

    <div class="content">
      <div>
        <p class="title"
           style=" line-height:33pt; margin:0pt"><span style="color:#222222; font-family:微软雅黑; font-size:25.5pt; font-weight:bold">2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">2020年3月各个省份的计算机二级考试报名时间出来啦，相信大家流程上都了解得差不多了，但是每年总会有一些小伙伴拍摄的报名照不合格导致耽误了报名时间，今天小编就来教大家快速拍一张好看又标准的计算机报名照。</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">而且报名照将用于合格证书，所以既要要看又要标准规范。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">我们先来看下考试报名照的拍摄要求。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">各个省份要求上传照片规格要求：</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="2e59e2e6-288c-48e7-ad4a-ee60688c8c47.001.jpeg"
               width="554"
               height="308"
               alt="2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:21pt; margin:0pt"><img src="2e59e2e6-288c-48e7-ad4a-ee60688c8c47.002.jpeg"
               width="554"
               height="308"
               alt="2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">现在</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">小编教大家</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">一分钟就做出上述符合要求的证件照。首先打开结婚证件照，选择首页计算机考试报名照，</span>
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="2e59e2e6-288c-48e7-ad4a-ee60688c8c47.003.jpeg"
               width="466"
               height="931"
               alt="2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="2e59e2e6-288c-48e7-ad4a-ee60688c8c47.004.jpeg"
               width="439"
               height="846"
               alt="2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" line-height:12pt; margin:12pt 0pt 12pt 7.5pt"><span style="color:#999999; font-family:微软雅黑; font-size:9pt">在首页选择你需要拍摄的规格</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">仔细核对 照片规格是否规范是你需要的</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="2e59e2e6-288c-48e7-ad4a-ee60688c8c47.005.jpeg"
               width="433"
               height="810"
               alt="2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">确认你要开始制作的照片，</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="2e59e2e6-288c-48e7-ad4a-ee60688c8c47.006.jpeg"
               width="437"
               height="824"
               alt="2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">照片上</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">传完成</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">后，系统将自动会生成一张合格的照片。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" line-height:21pt; margin:0pt"><img src="2e59e2e6-288c-48e7-ad4a-ee60688c8c47.007.jpeg"
               width="439"
               height="817"
               alt="2020年3月计算机二级考试报名照，一分钟就能做好，标准又好看"
               style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
        </p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">&#xa0;</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">制作完成后保存到手机相册就可以直接使用啦。</span></p>
        <p style=" margin:12pt 0pt"><span style="color:#222222; font-family:微软雅黑; font-size:12pt">最最主要的就是要核对好照片自己省份的照片要求~，最后祝大家都能顺利报考，</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">高分拿</span><span style="color:#222222; font-family:微软雅黑; font-size:12pt">证</span></p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:等线; font-size:10.5pt">&#xa0;</span></p>
      </div>
    </div>

    <!-- 公共底部 -->
    <div v-if="isMobile">
      <idphoto-mfooter></idphoto-mfooter>
    </div>
    <div v-else>
      <idphoto-footer></idphoto-footer>
    </div>
  </div>
</template>
<script>
import IdphotoHeader from '../../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../../component/idphoto/footer/footer.vue';
import IdphotoMheader from '../../../../component/idphoto/m_header/m_header.vue';
import IdphotoMfooter from '../../../../component/idphoto/m_footer/m_footer.vue';
export default {
  name: 'about',
  components: {
    IdphotoHeader,
    IdphotoFooter,
    IdphotoMheader,
    IdphotoMfooter
  },
  data () {
    return {
      isMobile: false
    }
  },
  mounted () {
    this.isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  },
}
</script>
<style lang="less" scoped>
@import "../../../../styles/idphoto/app.less";
@import "../artical.less";
</style>