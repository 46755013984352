<template>
  <div class="home-wrap"
       @mousedown="stopImgDrag">
    <!-- 公用头部 -->
    <photo-header></photo-header>

    <!-- 主体内容 start -->
    <home-top></home-top>

    <!-- 10秒智能翻新 -->
    <home-renew :reNew="reNew"
                @clickReNew="clickReNew"
                @moveReNew="moveReNew"
                @clickSmallPic="clickSmallPic"></home-renew>

    <!-- 人工精修破损老照片 -->
    <home-refine :reFine="reFine"
                 @clickReFine="clickReFine"
                 @moveReFine="moveReFine"
                 @clickSmallPic="clickSmallPic"></home-refine>

    <!-- 手机误删照片恢复 -->
    <home-redata></home-redata>

    <!-- 主体内容 end -->

    <!-- 公用底部 -->
    <photo-footer :showFooter="showFooter"></photo-footer>

  </div>
</template>

<script>
import HomeTop from './home-top.vue';
import HomeRenew from './home-renew.vue';
import HomeRefine from './home-refine.vue';
import HomeRedata from './home-redata.vue';
import PhotoHeader from '../../../component/rephoto/header/header.vue';
import PhotoFooter from '../../../component/rephoto/footer/footer.vue';
// import PhotoHeader from '../header/header.vue';
// import PhotoFooter from '../footer/footer.vue';

export default {
  name: 'home',
  components: {
    HomeTop,
    HomeRenew,
    HomeRefine,
    HomeRedata,
    PhotoHeader,
    PhotoFooter,
  },
  data () {
    return {
      // 翻新
      reNew: {
        index: 0,
        btnLeft: '50%',
        wrapWidth: "100%",
        oldPicWidth: '50%',
        newPicWidth: '50%',
        bigPics: [{
          "old": require("../../../static/imgs/rephoto/pc/renew4_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/renew4_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/renew2_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/renew2_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/renew3_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/renew3_new.jpg")
        }],
        smallPics: [{
          "title": "低分辨率示例",
          "url": require("../../../static/imgs/rephoto/pc/renew4_new.jpg"),
        }, {
          "title": "模糊照片示例",
          "url": require("../../../static/imgs/rephoto/pc/renew2_new.jpg"),
        }, {
          "title": "旧照片示例",
          "url": require("../../../static/imgs/rephoto/pc/renew3_new.jpg"),
        }]
      },
      // 精修
      reFine: {
        index: 0,
        txt: ["小众选择", "用户热推", "修复师力荐"],
        btnLeft: '50%',
        wrapWidth: "100%",
        oldPicWidth: '50%',
        newPicWidth: '50%',
        bigPics: [{
          "old": require("../../../static/imgs/rephoto/pc/low_refine_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/low_refine_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/middle_refine_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/middle_refine_new.jpg")
        }, {
          "old": require("../../../static/imgs/rephoto/pc/high_refine_old.jpg"),
          "new": require("../../../static/imgs/rephoto/pc/high_refine_new.jpg")
        }],
        smallPics: [{
          "title": "初级修复",
          "url": require("../../../static/imgs/rephoto/pc/low_refine_new.jpg"),
        }, {
          "title": "中级修复",
          "url": require("../../../static/imgs/rephoto/pc/middle_refine_new.jpg"),
        }, {
          "title": "高级修复",
          "url": require("../../../static/imgs/rephoto/pc/high_refine_new.jpg"),
        }]
      },
      // 变年轻
      reYoung: {
        index: 0,
        autoPlay: "",
        bigvideo: [{
          "url": require("../../../static/imgs/rephoto/young1.mp4")
        }, {
          "url": require("../../../static/imgs/rephoto/young2.mp4")
        }, {
          "url": require("../../../static/imgs/rephoto/young3.mp4")
        }],
        smallVideo: [{
          "url": require("../../../static/imgs/rephoto/pc/home_video1.png"),
        }, {
          "url": require("../../../static/imgs/rephoto/pc/home_video2.png"),
        }, {
          "url": require("../../../static/imgs/rephoto/pc/home_video3.png"),
        }]
      },

      showFooter: true
    }
  },
  mounted () {
    // 初始化翻新拖拽按钮尺寸
    let reNewObj = this.initBtnPos('pic1Wrap');
    this.reNew.wrapWidth = reNewObj.width;
    // console.log("reNewObj.width ", reNewObj.width)
    // this.reNew.btnLeft = reNewObj.left;

    // 初始化精修拖拽按钮尺寸
    let reFineObj = this.initBtnPos('pic2Wrap');
    this.reFine.wrapWidth = reFineObj.width;
    // this.reFine.btnLeft = reFineObj.left;

  },
  methods: {

    stopImgDrag (e) {
      e.target.onmousedown = function (e) {
        e.preventDefault()
      };
    },
    initBtnPos (id) {
      let maxWidth = document.getElementById(id).offsetWidth;
      return {
        width: maxWidth,
        left: maxWidth / 2 + 'px'
      }
    },

    move (e, obj) {
      let oDiv = e.target;    //获取目标元素

      //算出鼠标相对元素的位置
      let disX = e.clientX - oDiv.offsetLeft;
      //   let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let maxWidth = obj.wrapWidth;
        // let maxWidth = picWrap.offsetWidth;
        // let top = e.clientY - disY;

        //绑定元素位置到positionX和positionY上面
        // this.positionX = top;
        // this.positionY = left;
        if (left < 0 || left > maxWidth) return;


        //移动当前元素
        // oDiv.style.left = left + 'px';
        // odiv.style.top = top + 'px';
        obj.btnLeft = left + 'px';

        //修改老图宽度
        // oldPic.style.width = left + 'px';
        obj.oldPicWidth = left + 'px';

        //修改新图宽度
        // newPic.style.width = (maxWidth - left) + 'px';
        obj.newPicWidth = (maxWidth - left) + 'px';

      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    clickImg (e, obj) {
      let left = e.offsetX;
      // 修改拖拽图标位置
      obj.btnLeft = left + 'px';
      //修改老图宽度
      obj.oldPicWidth = left + 'px';
      //修改新图宽度
      obj.newPicWidth = (obj.wrapWidth - left) + 'px';
    },

    // 翻新拖拽
    moveReNew (e) {
      this.move(e, this.reNew);
    },
    moveReFine (e) {
      this.move(e, this.reFine);
    },
    //点击翻新模块
    clickReNew (e) {
      this.clickImg(e, this.reNew);
    },
    // 点击精修模块
    clickReFine (e) {
      this.clickImg(e, this.reFine);
    },
    // 点击缩略图部分
    clickSmallPic (type, index) {
      switch (type) {
        case 1:
          this.reNew.index = index;
          break;

        case 2:
          this.reFine.index = index;
          break;

        case 3:
          this.reYoung.index = index;
          this.reYoung.autoPlay = "autoplay";
          break;
      }
    }
  }
}
</script>

<style lang="less">
@import "./home.less";
</style>