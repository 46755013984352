<template>
  <div class="zuimei-wrap">
    <!-- 第一屏 -->
    <div class="zuimei-wrap-bg1">
      <div class="zuimei-bg1-top">
        <img src="../../static/imgs/zuimei/pic_12.png" alt="" />
      </div>
      <div class="zuimei-bg1-title">最美证件照专业版</div>
      <div class="zuimei-bg1-btn" @click="download">免费下载使用</div>
      <div class="zuimei-quanxian">
        <p>【 V2.1.8 版本 · 软件大小 21.6MB 】</p>
        <p>开发者：苏州普物科技有限公司</p>
        <p>
          <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;|&nbsp;<a
            class="blue"
            href="https://oss.puwu.info/tphoto/web/privacy_timephoto.html"
            >隐私协议</a
          >
        </p>
      </div>
      <div class="zuimei-bg1-list">
        <div class="bg1-list">
          <p>
            <img src="../../static/imgs/zuimei/ic_gaoqing.png" alt="" />
          </p>
          <p>高清证件照</p>
        </div>
        <div class="bg1-list">
          <p>
            <img src="../../static/imgs/zuimei/ic_gaoqing.png" alt="" />
          </p>
          <p>自然美颜</p>
        </div>
        <div class="bg1-list">
          <p>
            <img src="../../static/imgs/zuimei/ic_gaoqing.png" alt="" />
          </p>
          <p>证件照换底</p>
        </div>
      </div>
      <div class="zuimei-bg1-list">
        <div class="bg1-list">
          <p>
            <img src="../../static/imgs/zuimei/ic_gaoqing.png" alt="" />
          </p>
          <p>发丝级抠图</p>
        </div>
        <div class="bg1-list">
          <p>
            <img src="../../static/imgs/zuimei/ic_gaoqing.png" alt="" />
          </p>
          <p>一照多用</p>
        </div>
        <div class="bg1-list">
          <p>
            <img src="../../static/imgs/zuimei/ic_gaoqing.png" alt="" />
          </p>
          <p>售后无忧</p>
        </div>
      </div>
    </div>

    <!-- end 第一屏 -->
    <!-- 第二屏 -->

    <div class="zuimei-wrap-bg">
      <div class="zuimei-top-txt">更懂你的证件照App</div>
      <div class="zuimei-bg-title"><span>最美证件照</span>专业版</div>
      <div class="zuimei-bg-tip">
        先进人脸识别算法，准确裁剪出标准证件照，人工智能美颜
        算法打造自然妆感，独家高清人像算法，确保证件照更合格
      </div>
      <div class="zuimei-table">
        <div class="zuimei-table-th">
          <div class="zuimei-table-td">
            <img src="../../static/imgs/zuimei/ic_gou.png" alt="" />最美证件照
          </div>
          <div class="zuimei-table-td">
            <img src="../../static/imgs/zuimei/ic_cha.png" alt="" />普通证件照
          </div>
        </div>
        <div class="zuimei-table-tr">
          <div class="zuimei-table-td">
            <img src="../../static/imgs/zuimei/pic_2_1.png" alt="" />
            <p>人工智能算法美颜</p>
          </div>
          <div class="zuimei-table-td">
            <img src="../../static/imgs/zuimei/pic_2_2.png" alt="" />
            <p>传统美颜算法,不真实</p>
          </div>
        </div>
        <div class="zuimei-table-tr">
          <div class="zuimei-table-td"><p>独家人像高清技术</p></div>
          <div class="zuimei-table-td">
            <p class="red">不支持高清人像</p>
          </div>
        </div>
        <div class="zuimei-table-tr">
          <div class="zuimei-table-td h58">
            <p>免费修改文件大小/分<br />辨率/重命名/添加文字</p>
          </div>
          <div class="zuimei-table-td"><p class="red">不支持此功能</p></div>
        </div>
        <div class="zuimei-table-tr">
          <div class="zuimei-table-td"><p>人工客服24h应答</p></div>
          <div class="zuimei-table-td"><p>无客服处理，或不及时</p></div>
        </div>
      </div>
    </div>

    <!-- 第三屏 -->

    <div class="zuimei-wrap-bg bg3">
      <div class="zuimei-top-txt2">仅需3个步骤</div>
      <div class="zuimei-bg-title"><b>自拍出</b>最美证件照</div>
      <div class="zuimei-bg-tip">
        只需拍摄/上传照片，系统即可自动生成<br />美颜算法加持，轻松拍出最美证件照
      </div>
      <div class="zuimei-bg3-gif">
        <lottie
          :options="defaultOptions"
          :height="240"
          :width="280"
          v-on:animCreated="handleAnimation"
        />
      </div>
      <div class="zuimei-bg3-list">
        <div class="bg3-list">
          <span><img src="../../static/imgs/zuimei/ic_01.png" alt="" /></span
          >拍摄/上传正面无遮挡照片,系统抠图
        </div>
        <div class="bg3-list">
          <span><img src="../../static/imgs/zuimei/ic_02.png" alt="" /></span
          >选中背景底色，美颜度，清晰度
        </div>
        <div class="bg3-list">
          <span><img src="../../static/imgs/zuimei/ic_03.png" alt="" /></span
          >一键即可保存到相册
        </div>
      </div>
    </div>

    <!-- 第四屏 -->

    <div class="zuimei-wrap-bg bg4">
      <div class="zuimei-bg-title">独家<b class="yellow">人脸高清</b>技术</div>
      <div class="zuimei-top-txt3">让手机拍出相馆级画质</div>
      <div class="zuimei-bg-tip">
        独家图像生成技术，清晰放大人像，五官<br />肤质优化，打造专业相机拍摄效果
      </div>
      <div class="zuimei-bg4-pic">
        <img src="../../static/imgs/zuimei/pic_4.png" alt="" />
      </div>
    </div>

    <!-- 第五屏 -->

    <div class="zuimei-wrap-bg bg5">
      <div class="zuimei-bg-title">满足所有证件照尺寸</div>
      <div class="zuimei-bg-tip">
        囊括500+种证件照尺寸<br />
        自定义功能确保为收纳尺寸要求
      </div>
      <div class="zuimei-bg5-list">
        <div class="bg5-list">
          <img src="../../static/imgs/zuimei/ic_changguicunzhao.png" alt="" />
          <div class="bg5-list-title">常规寸照</div>
          <p>小一寸 大一寸、大二寸 社保照、身份证照<br />.....</p>
        </div>
        <div class="bg5-list">
          <img src="../../static/imgs/zuimei/ic_baomingzhao.png" alt="" />
          <div class="bg5-list-title">考试报名照</div>
          <p>
            英语四六级、 普通话水测试、 计算机等级考试、教师资格证<br />.....
          </p>
        </div>
        <div class="bg5-list">
          <img src="../../static/imgs/zuimei/ic_qianzhengzhao.png" alt="" />
          <div class="bg5-list-title">签证照</div>
          <p>泰国签证、日本签证 美国签证、新加坡签证 新西兰签证<br />.....</p>
        </div>
        <div class="bg5-list">
          <img src="../../static/imgs/zuimei/ic_qita.png" alt="" />
          <div class="bg5-list-title">其他</div>
          <p>简历照、公务员考试 司法考试、事业单位招聘<br />.....</p>
        </div>
        <div class="bg5-list-center">自定义尺寸</div>
      </div>
    </div>

    <!-- 第六屏 -->

    <div class="zuimei-wrap-bg bg6">
      <div class="zuimei-bg-title">免费修改</div>
      <div class="zuimei-bg6-mintitle">文件大小、dpi、重命名、添加文字</div>
      <div class="zuimei-bg-tip">
        证件照保存成功后，均可免费无限次修改，<br />
        确保照片合格好用。
      </div>
      <div class="zuimei-bg6-pic">
        <img src="../../static/imgs/zuimei/pic_6.png" alt="" />
      </div>
      <div class="zuimei-bg6-box">
        <div class="zuimei-bg6-details line1">
          <div class="bg6-details-name">照片重命名</div>
          <div class="bg6-details-center">30750198001020021.jpg</div>
        </div>
        <div class="zuimei-bg6-details line2">
          <div class="bg6-details-name">文件大小</div>
          <div class="bg6-details-center"></div>
        </div>
        <div class="zuimei-bg6-details line3">
          <div class="bg6-details-name">分辨率</div>
          <div class="bg6-details-center">
            <span>72dpi</span>
            <span>150dpi</span>
            <span>300dpi</span>
          </div>
        </div>
        <div class="zuimei-bg6-details">
          <div class="bg6-details-name">图片加文字</div>
          <div class="bg6-details-center">可添加身份证号/姓名等</div>
        </div>
      </div>
    </div>

    <!-- 第七屏 -->

    <div class="zuimei-wrap-bg bg7">
      <div class="zuimei-bg7-title">真实用户评价</div>
      <div class="zuimei-bg-tip">
        已有<b>32428</b>个用户给出五星好评，好评率达99%
      </div>
      <div class="zuimei-pingfen">
        <p><span>4.9</span><br />综合评分</p>
      </div>
      <div class="zuimei-bg7-sliders">
        <swiper ref="evalSwiper" :options="evalSwiperOptions">
          <swiper-slide>
            <div class="bg7-sliders-details">
              <div class="bg7-slider-name">
                <p>
                  <img
                    src="https://oss.puwu.info/public/web/header/1.jpg"
                    alt=""
                  />
                </p>
                最美***
              </div>
              <div class="bg7-slider-txt">
                作为多年证件照老用户，真心觉得证件照制作是用心做产品的app，精细打磨的效果，努力开辟新功能，都很惊喜。希望越来越好。点赞
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="bg7-sliders-details">
              <div class="bg7-slider-name">
                <p>
                  <img
                    src="https://oss.puwu.info/public/web/header/2.jpg"
                    alt=""
                  />
                </p>
                可爱的***
              </div>
              <div class="bg7-slider-txt">
                证件照软件很好用，一键换背景超级满意 
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="bg7-sliders-details">
              <div class="bg7-slider-name">
                <p>
                  <img
                    src="https://oss.puwu.info/public/web/header/3.jpg"
                    alt=""
                  />
                </p>
                胖三***
              </div>
              <div class="bg7-slider-txt">
                软件功能丰富，有不同的照片尺寸，还能换正装
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="bg7-sliders-details">
              <div class="bg7-slider-name">
                <p>
                  <img
                    src="https://oss.puwu.info/public/web/header/4.jpg"
                    alt=""
                  />
                </p>
                你的***
              </div>
              <div class="bg7-slider-txt">
                拍了一张100KB太大,然后可以免费改成50KB,赞赞赞
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="bg7-sliders-details">
              <div class="bg7-slider-name">
                <p>
                  <img
                    src="https://oss.puwu.info/public/web/header/5.jpg"
                    alt=""
                  />
                </p>
                红衣少***
              </div>
              <div class="bg7-slider-txt">
                这个比别的拍的清晰，还有美颜，喜欢！
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="bg7-sliders-details">
              <div class="bg7-slider-name">
                <p>
                  <img
                    src="https://oss.puwu.info/public/web/header/6.jpg"
                    alt=""
                  />
                </p>
                帅萌的***
              </div>
              <div class="bg7-slider-txt">
                疫情复工公司同事推荐用来拍通行证照片的，拍得证件照不错呐!
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="bg7-sliders-details">
              <div class="bg7-slider-name">
                <p>
                  <img
                    src="https://oss.puwu.info/public/web/header/7.jpg"
                    alt=""
                  />
                </p>
                王七的***
              </div>
              <div class="bg7-slider-txt">证件照制作效果也很自然，好评！</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="zuimei-bg7-title">7*24小时客服在线</div>
      <!-- <div class="zuimei-bg7-mintitle">专业客服全天在线答疑</div> -->
      <div class="zuimei-bg-tip">
        专业客服全天答疑，针对证件照的任何问题均可
        咨询客服，我们将竭诚问你服务。
      </div>
      <div class="zuimei-bg7-kefu">
        <div
          class="bg7-kefu-list tag-read"
          data-clipboard-text="13738817358"
          @click="getWxMsg"
        >
          <p>
            <img src="../../static/imgs/zuimei/ic_zaixiankefu.png" alt="" />
          </p>
          在线客服
        </div>
        <div
          class="bg7-kefu-list tag-read"
          data-clipboard-text="13738817358"
          @click="getWxMsg"
        >
          <p>
            <img src="../../static/imgs/zuimei/ic_weixin.png" alt="" />
          </p>
          微信客服
        </div>
        <a class="bg7-kefu-list" href="tel:051287816685">
          <p>
            <img src="../../static/imgs/zuimei/ic_dianhuakefu.png" alt="" />
          </p>
          电话客服
        </a>
      </div>
      <div class="zuimei-footer-page">
        <p>
          开发者：苏州普物科技有限公司&nbsp;
          <a href="http://beian.miit.gov.cn" target="_blank"
            >苏ICP备18052115号-6</a
          >
        </p>
        <p>
          最美证件照专业版 V2.1.8
          <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
            class="blue"
            href="https://oss.puwu.info/tphoto/web/privacy_timephoto.html"
            >隐私协议</a
          >
        </p>
        <!-- <p>
              地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
            </p>
            <p>邮箱：feedback@techpuwu.com &nbsp; 电话：0512-87816685</p> -->
      </div>
    </div>

    <div class="swiper-pagination" slot="pagination"></div>

    <!-- 底部悬浮按钮 -->
    <div class="zuimei-btn" @click="download">免费下载使用</div>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :leftBtnTxt="leftBtnTxt"
      :rightBtnTxt="rightBtnTxt"
      :showDoubleBtn="showDoubleBtn"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @leftBtnFn="leftBtnFn"
      @rightBtnFn="rightBtnFn"
    >
    </common-dialog>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import commonDialog from "../../component/dialog/dialog.vue";
import * as animationData from "./zuimei.json";
export default {
  name: "zuimei",
  components: {
    commonDialog,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    let that = this;
    return {
      defaultOptions: { animationData: animationData.default },

      isShowDialog: false,
      dialogTitle: "微信号复制成功",
      dialogContent: "打开微信—添加好友—长按粘贴微信号即可添加",
      showDoubleBtn: false,
      showSingleBtn: false,
      isAlignLeft: false,
      leftBtnTxt: "去添加好友",
      rightBtnTxt: "关闭",

      sliderIndex: 0,
      swiperOptions: {
        direction: "vertical",
        effect: "slide",
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false, // 循环模式选项
        initialSlide: 0,
        autoplay: 0,
        navigation: {
          nextEl: ".swiper-button-next", //前进按钮的css选择器或HTML元素。
          prevEl: ".swiper-button-prev", //后退按钮的css选择器或HTML元素。
        },
        on: {
          slideChangeTransitionStart: function () {
            that.sliderIndex = this.realIndex; //获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
            // console.log(that.sliderIndex);
          },
        },
      },
      evalSwiperOptions: {
        autoplay: {
          delay: 3000,
        },
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 30, // 角度调整
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false, //是否开启阴影
        },
      },

      isIOS: false,
    };
  },
  mounted() {
    document.title = "最美证件照专业版";

    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf("mac os") > -1;

    // let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    // if (!flag) {
    //   this.$router
    //     .push({
    //       name: "home",
    //     })
    //     .catch((err) => {
    //       err;
    //     });
    // }
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
      this.sliderIndex = index;
    },
    download() {
      let iosUrl = "https://apps.apple.com/cn/app/id1489463460";
      let anzhuoApk =
        "https://oss.puwu.info/tphoto/update/最美证件照专业版.apk";
      let goUrl = this.isIOS ? iosUrl : anzhuoApk;
      window.open(goUrl, "_blank");
    },
    leftBtnFn() {
      this.isShowDialog = false;
    },
    rightBtnFn() {
      this.isShowDialog = false;
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    },
    handleAnimation2: function (anim) {
      this.anim2 = anim;
      console.log(anim); //这里可以看到 lottie 对象的全部属性
    },
    getWxMsg() {
      let clipboard = new Clipboard(".tag-read");
      clipboard.on("success", (e) => {
        // alert('微信号复制成功，快去添加微信客服吧')
        this.dialogTitle = "微信号复制成功";
        this.isShowDialog = true;
        this.showDoubleBtn = true;
        this.showSingleBtn = false;
        this.isAlignLeft = false;
        this.dialogContent = "打开微信—添加好友—长按粘贴微信号即可添加";
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        // 不支持复制
        this.isShowDialog = true;
        this.dialogTitle = "温馨提示";
        this.showDoubleBtn = false;
        this.showSingleBtn = true;
        this.isAlignLeft = false;
        this.dialogContent = "该浏览器不支持自动复制";
        // alert('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy();
        // e.preventDefault();
      });
    },
    showAuthorityBox() {
      this.dialogTitle = "获取权限说明";
      this.isShowDialog = true;
      this.showDoubleBtn = false;
      this.showSingleBtn = true;
      this.isAlignLeft = true;
      this.dialogContent =
        "为给您提供更优质的服务，最美证件照专业版App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片制作证件照；<br/>2. 相机权限：用于拍摄照片制作证件照；<br/>3. 存储权限： 用于保存制作好的证件照；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
  },
};
</script>
<style lang="less">
@import "./zuimei.less";
</style>