<template>
  <div class="photo-wrap"
       v-show="showFooter"
       :class="{'fixed':isFixed}">
    <div class="photo-footer">
      <div class="pc-show">{{data.companyName}} &nbsp;&nbsp; 地址：{{data.detailAddress}}<br />
        电话：{{data.tel}} &nbsp;&nbsp;&nbsp;&nbsp; 邮箱：{{data.email}}<br /></div>
      Copyright@2018{{data.companyName}} &nbsp;&nbsp; <a href="http://beian.miit.gov.cn"
         target="_blank">苏ICP备18052115号-4</a>
    </div>
  </div>
</template>
<script>
import data from '../../../static/json/data.json'
export default {
  name: 'photo-footer',
  props: {
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      data: data,
      isFixed: false
    }
  },
  mounted () {
    if (this._isMobile()) {
      this.isFixed = this.$route.name == 'about';
    }
  },
  methods: {
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  }
}
</script>
<style lang="less" scoped>
@import "./footer.less";
</style>