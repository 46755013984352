<template>
  <div class="nav-bar">
    <ul class="flex_box flex_align_center flex_justify_center">
      <li class="logo flex_4">
        <img src="../../../static/imgs/idphoto/m_logo_puwu@2x.png" alt="#"/>
        <span>普物科技</span>
      </li>
      <li v-for="(item,i) in navNames"
          :key="i"
          :class="{'active': i == curIndex}"
          @click="jumpUrl(i)">
        <a>{{item}}</a>
      </li>
      <li class="flex_1"></li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'idphoto-header',
  props: {

  },
  data () {
    return {
      curIndex: 0,
      curName: '',
      navNames: ['首页', '结婚登记照', '印象证件照', '新闻资讯', '关于我们']
    }
  },
  mounted () {
    this.curName = this.$route && this.$route.name;
    switch (this.curName) {
      case 'index':
        this.curIndex = 0;
        break;

      case 'jiehun':
        this.curIndex = 1;
        break;

      case 'yinxiang':
        this.curIndex = 2;
        break;

      case 'zixun':
        this.curIndex = 3;
        break;

      case 'idp_about':
        this.curIndex = 4;
        break;
    }

    if (this.curName.indexOf('artical') > -1) {
      this.curIndex = 3;
    }
    let ua = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad|phone/i.test(ua) && this.curName.indexOf('artical') == -1) {
      let names = ['m_index', 'm_jiehun', 'm_yinxiang', 'm_zixun', 'm_about'];
      this.$router.push({
        name: names[this.curIndex]
      }).catch(err => { err })
    }
  },
  methods: {
    jumpUrl (idx) {
      //   let index = idx - 1;
      let names = ['index', 'jiehun', 'yinxiang', 'zixun', 'idp_about'];
      this.$router.push({
        name: names[idx]
      }).catch(err => { err })
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/app.less";
@import "./header.less";
</style>