<template>
  <div class="footer">
    <div class="map flex_box flex_align_center flex_justify_center c">
      <!-- <div class=""><a>首页</a></div>
      <div class="">|</div>
      <div class=""><a>结婚证件照</a></div>
      <div class="">|</div>
      <div class=""><a>印象证件照</a></div>
      <div class="">|</div>
      <div class=""><a>新闻资讯</a></div>
      <div class="">|</div>
      <div class=""><a>关于我们</a></div> -->
      <div
        class="name"
        v-for="(item, index) in navNames"
        :key="index"
        @click="jumpUrl(index)"
      >
        <span v-if="index != 0">|</span><a>{{ item }}</a>
      </div>
    </div>
    <p>
      Copyright@2021苏州普物科技有限公司
      <a href="http://beian.miit.gov.cn" target="_blank"
        >苏ICP备18052115号-6</a
      >
    </p>
    <p>
      地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
    </p>
    <p>邮箱：feedback@techpuwu.com &nbsp; 电话：0512-87816685</p>
  </div>
</template>
<script>
export default {
  name: "idphoto-mfooter",
  props: {},
  data() {
    return {
      curIndex: 0,
      curName: "",
      navNames: ["首页", "结婚证件照", "印象证件照", "新闻资讯", "关于我们"],
    };
  },
  mounted() {
    this.curName = this.$route && this.$route.name;
    switch (this.curName) {
      case "m_index":
        this.curIndex = 0;
        break;

      case "m_jiehun":
        this.curIndex = 1;
        break;

      case "m_yinxiang":
        this.curIndex = 2;
        break;

      case "m_zixun":
        this.curIndex = 3;
        break;

      case "m_about":
        this.curIndex = 4;
        break;
    }
  },
  methods: {
    jumpUrl(idx) {
      //   let index = idx - 1;
      let names = ["m_index", "m_jiehun", "m_yinxiang", "m_zixun", "m_about"];
      this.$router
        .push({
          name: names[idx],
        })
        .catch((err) => {
          err;
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/m_app.less";
@import "./m_footer.less";
</style>