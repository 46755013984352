<template>
  <div>

    <!-- 公共导航 -->
    <idphoto-header></idphoto-header>

    <!-- <div class="banner">
      <div class="banner-content flex_box flex_align_center">
        <div class="left-cont">
          <h1>结婚证件照</h1>
          <h3>全网唯一一家在线制作结婚登记照app</h3>
          <p>
            支持常规寸照、报名照、签证照、社保照等500种证件照规格
          </p>
          <div class="download-box flex_box">
            <div class="">
              <img class="qrcode"
                   src="../../../static/imgs/idphoto/erweima@2x.png"
                   alt="" />
            </div>
            <div class="">
              <div class="btn-down ios"
                   @click="iosDownload">
                <img src="../../../static/imgs/idphoto/ic_ios@2x.png"
                     alt="" />
                iOS下载
              </div>
              <div class="btn-down anzhuo"
                   @click="anzhuoDownload">
                <img src="../../../static/imgs/idphoto/ic_anzhuo@2x.png"
                     alt="" />
                安卓下载
              </div>
            </div>
          </div>
        </div>
        <div class="right-cont">
          <img src="../../../static/imgs/idphoto/shouye_tu1@2x.png"
               alt="" />
        </div>
      </div>
    </div> -->

    <div class="banner">
      <div class="banner-content flex_box flex_align_center">
        <div class="left-cont">
          <h1>印象证件照</h1>
          <h3>简单易用的专业证件照制作软件</h3>
          <div class="download-box flex_box">
            <div class="">
              <img class="qrcode"
                   src="../../../static/imgs/idphoto/yxQRCode@2x.png"
                   alt="" />
            </div>
            <div class="">
              <div class="btn-down ios"
                   @click="iosDownload">
                <img src="../../../static/imgs/idphoto/ic_ios@2x_1.png"
                     alt="" />
                iOS下载
              </div>
              <div class="btn-down anzhuo"
                   @click="anzhuoDownload">
                <img src="../../../static/imgs/idphoto/ic_anzhuo@2x_1.png"
                     alt="" />
                安卓下载
              </div>
            </div>
          </div>
        </div>
        <div class="right-cont">
          <img src="../../../static/imgs/idphoto/yinxiang_tu1@2x.png"
               alt="" />
        </div>
      </div>
    </div>

    <div class="product-box flex_box">
      <div class="jiehun flex_1">
        <div class="p-content flex_box">
          <div class="flex_1 p-content-l">
            <h1>
              <img src="../../../static/imgs/idphoto/logo_jiehun@2x.png"
                   alt="" />结婚证件照
            </h1>
            <h3>全网唯一一家在线制作结婚登记照app</h3>
            <p>
              支持常规寸照、考试报名照、签证照社保照等500
              种证件照规格随时随地，即拍即得
            </p>
            <div class="btn-more"><a>了解详情</a></div>
          </div>
          <div class="flex_1">
            <img class="p-img"
                 src="../../../static/imgs/idphoto/shouye_tu2@2x.png"
                 alt="" />
          </div>
        </div>
      </div>
      <div class="zhengjian flex_box flex_1">
        <div class="p-content flex_box">
          <div class="flex_1 p-content-l">
            <h1>
              <img src="../../../static/imgs/idphoto/logo_yinxiang@2x.png"
                   alt="" />印象证件照
            </h1>
            <h3>一个好的印象，一个好的开始</h3>
            <p>
              500种证件照在线拍摄制作，这一刻让
              证件照好看又专业起来
            </p>
            <div class="btn-more"><a>了解详情</a></div>
          </div>
          <div class="flex_1">
            <img class="p-img"
                 src="../../../static/imgs/idphoto/shouye_tu3@2x.png"
                 alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <idphoto-footer></idphoto-footer>

  </div>
</template>
<script>
import IdphotoHeader from '../../../component/idphoto/header/header.vue';
import IdphotoFooter from '../../../component/idphoto/footer/footer.vue';
export default {
  name: 'index',
  components: {
    IdphotoHeader,
    IdphotoFooter
  },
  data () {
    return {
    }
  },
  mounted () {
    document.title = "印象证件照";
  },
  methods: {
    iosDownload () {
      location.href = 'https://itunes.apple.com/cn/app/id1445293660';
    },
    anzhuoDownload () {
      location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.idphoto'
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/app.less";
@import "./index_pw.less";
</style>