<template>
  <div>
    <h2 class="studio_logo"></h2>

    <div class="banner">
      <div class="banner-content flex_box flex_align_center">
        <div class="left-cont">
          <h1>证件照相馆</h1>
          <h3>简单易用的专业证件照制作软件</h3>

          <div class="pc-show">
            <div class="download-box flex_box">
              <div>
                <img class="qrcode"
                     src="../../../static/imgs/idphoto/studio/qrcode2.png"
                     alt="" />
              </div>
              <div class="">
                <div class="btn-down ios"
                     @click="download(2)">
                  <img src="../../../static/imgs/idphoto/studio/ic_ios.png"
                       alt="" />
                  iOS下载
                </div>
                <div class="btn-down anzhuo"
                     @click="download(1)">
                  <img src="../../../static/imgs/idphoto/studio/ic_anzhuo.png"
                       alt="" />
                  安卓下载
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="right-cont">
          <img src="../../../static/imgs/idphoto/studio/home_pic1.png"
               alt="" />
        </div>
        <div class="phone-show apk-btn-dw"
             @click="download(3)"></div>
        <div class="phone-show app-version">大小：18.8MB 版本号：1.6.2</div>
      </div>
    </div>

    <!-- 免费制作证件照 -->
    <div class="studio-block-wrap">
      <div class="studio-block">
        <div class="studio-block-right">
          <p class="pt pc-show">ID PHOTO</p>
          <h3>免费制作证件照</h3>
          <p>全网唯一真正免费制作证件照app</p>
          <div class="download-btn pc-show">
            <a href=""
               class="ios"
               @click="download(2)"></a>
            <a href=""
               class="anzhuo"
               @click="download(1)"></a>
          </div>
        </div>
        <div class="studio-block-left">
          <img src="../../../static/imgs/idphoto/studio/home_pic2.png"
               alt="" />
        </div>
      </div>
    </div>

    <!-- 特色产品 -->
    <div class="studio-block-wrap white">
      <div class="studio-block featured-products">
        <h3 class="title">特色产品</h3>
        <div class="products-list">
          <!-- <span class="dot on"
                index=0>精致正装照</span>
          <b>|</b>
          <span class="dot"
                index=1>结婚证件照</span>
          <b>|</b>
          <span class="dot"
                index=2>自定义规格</span> -->

          <span class="dot"
                :class="{'on':  i == imgIndex}"
                v-for="(item, i) in sliderTxt"
                :key="i"
                @click="slideTo(i)">{{item}}</span>
        </div>

        <!-- 如果需要导航按钮 -->
        <div class="sliders-block swiper-container">
          <div class="pc-show">
            <div class="swiper-button-prev left-btn"></div>
            <div class="swiper-button-next right-btn"></div>
          </div>
          <div class="swiper-wrapper">
            <swiper ref="mySwiper"
                    :options="swiperOptions">
              <swiper-slide>
                <img src="../../../static/imgs/idphoto/studio/home_id_pic.png"
                     alt="">
              </swiper-slide>
              <swiper-slide>
                <img src="../../../static/imgs/idphoto/studio/home_marry_pic.png"
                     alt="">
              </swiper-slide>
              <swiper-slide>
                <img src="../../../static/imgs/idphoto/studio/home_free_pic.png"
                     alt="">
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="slider-txt">
          <span class="dot2"
                :class="{'on': imgIndex == index}"
                v-for="(item, index) in sliderTxt"
                :key="index"
                @click="slideTo(index)">{{item}}</span>
        </div>
        <p class="slider-mintxt">Exquisite formal photo</p>
        <div class="download-btn"
             @click="download(3)">立即下载</div>
        <p class="tip pc-show">
          一张证件照，让你从这里开始改变。
        </p>
      </div>
    </div>

    <!-- 制作流程 -->
    <div class="studio-block-wrap">
      <div class="studio-block featured-products">
        <h3 class="title">制作流程</h3>
        <div class="products-list">
          仅需四步，快速制作专业证件照
        </div>
        <div class="studio-step">
          <dl>
            <dt>
              <img src="../../../static/imgs/idphoto/studio/home_step_pic1.png"
                   alt="">
            </dt>
            <dd class="step">
              Step1
            </dd>
            <dd class="title">
              搜索拍摄规格
            </dd>
            <dd class="txt">
              首页搜索拍摄规格进行拍摄
            </dd>
          </dl>
          <dl>
            <dt>
              <img src="../../../static/imgs/idphoto/studio/home_step_pic2.png"
                   alt="">
            </dt>
            <dd class="step">
              Step2
            </dd>
            <dd class="title bg">
              手机拍摄
            </dd>
            <dd class="txt">
              选择干净背景进行拍摄
            </dd>
          </dl>
          <dl>
            <dt>
              <img src="../../../static/imgs/idphoto/studio/home_step_pic3.png"
                   alt="">
            </dt>
            <dd class="step">
              Step3
            </dd>
            <dd class="title">
              背景色选择
            </dd>
            <dd class="txt">
              进行美颜设置，选择背景色
            </dd>
          </dl>
          <dl>
            <dt>
              <img src="../../../static/imgs/idphoto/studio/home_step_pic4.png"
                   alt="">
            </dt>
            <dd class="step">
              Step4
            </dd>
            <dd class="title last">
              保存照片
            </dd>
            <dd class="txt">
              预览制作照片，保存照片到相册
            </dd>
          </dl>
        </div>
      </div>
    </div>

    <!-- 宣传 -->
    <div class="studio-block-wrap white">
      <div class="studio-block pro">
        <div class="studio-block-right">
          <p class="pt pc-show">ID PHOTO</p>
          <h3>证件照相馆</h3>
          <p class="studio-decoration">做你的口袋专业证件照相馆</p>
          <div class="download-btn pc-show">
            <a href=""
               class="ios"
               @click="download(2)"></a>
            <a href=""
               class="anzhuo"
               @click="download(1)"></a>
          </div>
        </div>
        <div class="studio-block-left">
          <div class="pc-show">
            <img src="../../../static/imgs/idphoto/studio/home_pic3.png"
                 alt="" />
          </div>
          <div class="phone-show">
            <img src="../../../static/imgs/idphoto/studio/home_mpic3.png"
                 alt="" />
          </div>
        </div>
        <br class="clear" />
      </div>
    </div>

    <!-- 底部banner -->
    <div class="footer">
      <div class="footer-content c">
        <!-- <div class="flex_box map">
                    <div class="flex_1"><a href="./studio_index.html">首页</a></div>
                    <div class="flex_1">|</div>
                    <div class="flex_1"><a href="./zixun.html">新闻资讯</a></div>
                    <div class="flex_1">|</div>
                    <div class="flex_1"><a href="./about.html">联系我们</a></div>
                </div> -->

        <div class="copyright">Copyright@2018苏州普物科技有限公司 <a href="http://beian.miit.gov.cn"
             target="_blank">苏ICP备18052115号-6</a></div>
        <div class="addr">地址：江苏苏州工业园区裕新路168号脉山龙大厦一号楼415室</div>
        <div class="contact">电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp; 邮箱：feedback@techpuwu.com</div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'm_jiehun',
  components: {
  },
  data () {
    let that = this;
    return {
      imgIndex: 0,
      sliderTxt: ['精致正装照', '结婚证件照', '自定义规格'],
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination'
        },
        // loop: true, // 循环模式选项
        initialSlide: 0,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false, //当切换到最后一个slide时是否自动停止切换，false：不停止；true:停止
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。
          //   reverseDirection: true, //开启反向自动轮播。
          //   waitForTransition: true //等待过渡完毕。自动切换会在slide过渡完毕后才开始计时。
        },
        navigation: {
          nextEl: ".swiper-button-next", //前进按钮的css选择器或HTML元素。
          prevEl: ".swiper-button-prev", //后退按钮的css选择器或HTML元素。
          // hieOnClick: true, //点击slide时显示/隐藏按钮
          // disabledClass: "my-button-disabled", //前进后退按钮不可用时的类名。
          // hiddenClass: "my-button-hidden" //按钮隐藏时的Class
        },
        on: {

          slideChangeTransitionStart: function () {
            that.imgIndex = this.realIndex;  //获取轮播图片下标索引；这里有一个坑，之前网上找到的是用activeIndex，但后来网上说的是这个realIndex，原来activeIndex是swiper2.0的；而realIndex是swiper3.0的，（使用realIndex才实现了下标索引）
          },
        },
      },
      isIOS: false,
      iosUrl: ''
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
  mounted () {
    // console.log('111', this.swiper.realIndex)
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf('mac os') > -1;
    this.iosUrl = "https://apps.apple.com/cn/app/id1489463460";
    console.log(this.$route)
    document.title = "证件照相馆";
  },
  methods: {
    slideTo (index) {
      this.swiper.slideTo(index, 1000, false);
      this.imgIndex = index;
    },
    download (type) {
      if (type == 1) {
        location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.onephoto";
      }
      else if (type == 2) {
        location.href = this.iosUrl;
      }
      else {
        location.href = this.isIOS ? this.iosUrl : "https://oss.puwu.info/tphoto/update/证件照相馆.apk";
      }
    }
  },
}
</script>
<style lang="less" scoped>
@import "../../../styles/idphoto/m_app.less";
@import "./m_studio.less";
</style>